import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';

import IMatch from '../../models/IMatch';
import IMatchResult from '../../models/IMatchResult';
import IMatchService from '../../services/IMatchService';
import MatchProvider from '../../providers/matchProvider';

import './matches.css';

interface IMatchesProps {
    matchResults: IMatchResult[]
}

function Matches(props: IMatchesProps) {

    const matchContainerRef = useRef<HTMLDivElement>(null);
    const [matchResults, setMatchResults] = useState<IMatchResult[]>(props.matchResults);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const onLoad = () => {
        if (matchContainerRef.current !== null) {
            const matchRows: NodeListOf<HTMLElement> = matchContainerRef.current.querySelectorAll<HTMLElement>(".match-row");
            if (matchRows.length > 0) {
                const lastMatchRow = matchRows[matchRows.length - 1] as HTMLElement;
                lastMatchRow.classList.add("last-match-row");
            }
        }
    };

    const isTomorrow = (date: Date) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (tomorrow.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    const toggleMatchDate = (e: React.MouseEvent<HTMLDivElement>) => {

        if (e.currentTarget !== undefined) {
            const target: HTMLElement = e.currentTarget;
            const col = target.closest(".col");
            if (col !== null) {
                if (col.classList.contains("match-list-collapse")) {
                    col.classList.remove("match-list-collapse");
                }
                else {
                    col.classList.add("match-list-collapse");
                }
            }
            const matchRow: HTMLElement | null = target.closest(".match-row");

            const container: HTMLElement | null = target.closest(".match-container");
            if (matchRow !== null) {
                const matchRowId: string = matchRow.id;
                if (container !== null) {
                    container.querySelectorAll<HTMLElement>(".match-col[data-toggle=" + matchRowId + "]").forEach(i => {
                        if (i.classList.contains("match-col-collapse")) {
                            i.classList.remove("match-col-collapse");
                        }
                        else {
                            i.classList.add("match-col-collapse");
                        }
                    });
                }
            }
        }

    }

    const renderMatches = () => {

        let matchDate: string = "";
        let matchRowId: string = "";
        let newDate: boolean = false;
        return matchResults.map((m, i) => {
            newDate = false;
            if (m.date.toLocaleDateString() !== matchDate) {
                matchDate = m.date.toLocaleDateString();
                matchRowId = "MatchRow" + i;
                newDate = true;
            }
            let homeWin: boolean = false;
            let awayWin: boolean = false;
            if (m.homeScore !== null && m.awayScore !== null) {
                if (m.homePenalties !== null && m.awayPenalties !== null && m.homeScore === m.awayScore) {
                    homeWin = m.homePenalties > m.awayPenalties;
                    awayWin = m.awayPenalties > m.homePenalties;
                }
                else {
                    homeWin = m.homeScore > m.awayScore;
                    awayWin = m.awayScore > m.homeScore;
                }
            }
            const matchState = m.state;
            let matchTimeHeader: string = ""; // "FT", "Tomorrow", "Day, Month Day"
            if (isTomorrow(m.date)) {
                matchTimeHeader = "Tomorrow";
            }
            else {
                matchTimeHeader = days[m.date.getDay()] + ", " + months[m.date.getMonth()] + " " + m.date.getDate();
            }
            //if (homeWin || awayWin || m.state === "Final") {
            //    matchTimeHeader = "Final"
            //}
            //else {
            //    if (isTomorrow(m.date)) {
            //        matchTimeHeader = "Tomorrow";
            //    }
            //    else {
            //        matchTimeHeader = days[m.date.getDay()] + ", " + months[m.date.getMonth()] + " " + m.date.getDate();
            //    }
            //}
            const matchDateLabel = days[m.date.getDay()] + ", " + months[m.date.getMonth()] + " " + m.date.getDate() + ", " + m.date.getFullYear();
            let matchTimeSubHeader: string = ""; // "Today", "hh:mm AM/PM", "Day, Month Day"
            matchTimeSubHeader = m.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            //const matchDateTime = m.date.toLocaleDateString() + " " + m.date.getHours() + ":" + m.date.getMinutes();
            const matchDateTime = m.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            //const matchDateTime = m.date.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short', hourCycle: 'h12' });
            return (
                <Fragment key={m.id}>
                    {
                        newDate ?
                            <div id={matchRowId} className="match-row p-0">
                                <div className="col bg-secondary fw-bold fs-6 text-white border-bottom border-dark" style={{ padding: "4px 8px" }}>
                                    <span className="match-list-toggle" style={{ cursor: "pointer" }} onClick={toggleMatchDate}>
                                    <i className="fa fa-caret-down" style={{ marginRight: "8px" }} />
                                        <span>{matchDateLabel}</span>
                                    </span>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <div key={m.id} data-toggle={matchRowId} className="match-col col p-0">
                        <div className="match border" style={{ padding: "16px", fontSize: "14px" }}>
                            <div style={{ marginBottom: "8px" }}>
                                <span>{m.label}</span>
                                <span style={{ float: "right" }}>{m.location.name === "" ? "" : m.location.name + " - " + m.field}</span>
                            </div>
                            <div className="d-flex">
                                <div className="flex-fill" style={{ textAlign: "left" }}>
                                    <div className={awayWin ? "d-flex align-content-center flex-nowrap match-winner" : "d-flex align-content-center flex-nowrap"} style={{ minHeight: "42px", borderRight: "1px solid grey" }}>
                                        <div className="align-self-center match-team-badge" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <img src={m.awayTeamBadgeUrl} height="24" width="24" />
                                        </div>
                                        <div className="align-self-center w-100 match-team-name">
                                            <a style={{ textDecoration: "none", color: "inherit" }}>{m.awayTeamName}</a>
                                        </div>
                                        <div className="align-self-center ms-auto text-end match-team-score" style={{ maxWidth: "60px", minWidth: "60px" }}>
                                            <span style={{ paddingRight: "10px" }}>{m.awayScore === null ? "" : m.awayScore}</span>
                                            <span className={m.awayPenalties === null ? "d-none" : ""}>
                                                <span style={{ paddingRight: "10px" }}>({m.awayPenalties === null ? "" : m.awayPenalties})</span>
                                            </span>
                                            <i className={awayWin ? "fa fa-caret-left" : "fa"} style={{ width: "7px", marginRight: "-2px" }} />
                                        </div>
                                    </div>
                                    <div className={homeWin ? "d-flex align-content-center flex-nowrap match-winner" : "d-flex align-content-center flex-nowrap"} style={{ minHeight: "42px", borderRight: "1px solid grey" }}>
                                        <div className="align-self-center match-team-badge" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <img src={m.homeTeamBadgeUrl} height="24" width="24" />
                                        </div>
                                        <div className="align-self-center w-100 match-team-name">
                                            <a style={{ textDecoration: "none", color: "inherit" }}>{m.homeTeamName}</a>
                                        </div>
                                        <div className="align-self-center ms-auto text-end match-team-score" style={{ maxWidth: "60px", minWidth: "60px" }}>
                                            <span style={{ paddingRight: "10px" }}>{m.homeScore === null ? "" : m.homeScore}</span>
                                            <span className={m.homePenalties === null ? "d-none" : ""}>
                                                <span style={{ paddingRight: "10px" }}>({m.homePenalties === null ? "" : m.homePenalties})</span>
                                            </span>
                                            <i className={homeWin ? "fa fa-caret-left" : "fa"} style={{ width: "7px", marginRight: "-2px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-fill align-self-center" style={{ textAlign: "center", minWidth: "100px", maxWidth: "100px", paddingLeft: "16px" }}>
                                    <div className="">{matchState}</div>
                                    <div className="d-none">{matchTimeHeader}</div>
                                    <div className="">{matchTimeSubHeader}</div>
                                    <div className="">
                                        {
                                            m.videoUrl !== "" ?
                                                <a href={m.videoUrl} target="_blank" style={{ textDecoration: "none", marginRight: "10px" }} title="Watch Game Replay"><i className="fa fa-video"></i></a>
                                                :
                                                <></>
                                        }
                                        <Link to={"/Competition/Match/Detail/" + m.id} style={{ textDecoration: "none" }} title="View Game Stats"><i className="fa fa-chart-bar"></i></Link>
                                        {
                                            m.location.mapUrl !== "" ?
                                                <a href={m.location.mapUrl} target="_blank" style={{ textDecoration: "none", marginLeft: "10px" }} title="View Map"><i className="fa fa-location-dot"></i></a>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        });
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div ref={matchContainerRef} className="match-container shadow">
            <div className="row" style={{ margin: "0" }}>
                {
                    matchResults.length === 0 ?
                        <div className="text-center">No Matches Found</div>
                        :
                        renderMatches()
                }
            </div>
        </div>
    );
}

export default Matches;

import IDivisionService from '../services/IDivisionService';
import DivisionRepository from '../repos/divisionRepository';

export default class DivisionProvider {

    public static getService(): IDivisionService {
        const repo: DivisionRepository = new DivisionRepository();
        return repo;
    }

}
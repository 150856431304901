import ICompetition from '../models/ICompetition';
import IDivision from '../models/IDivision';
import IOrganization from '../models/IOrganization';
import ITeam from '../models/ITeam';
import IBracket from '../models/IBracket';
import IPlayer from '../models/IPlayer';
import ILocation from '../models/ILocation';

import competitionData from '../data/competitions.json';
import bracketData from '../data/brackets.json';
import divisionData from '../data/divisions.json';
import organizationData from '../data/organizations.json';
import teamData from '../data/teams.json';
import playerData from '../data/players.json';
import locationData from '../data/locations.json';

export default class DataRepository {


    public static getCompetitionById(id: string): ICompetition {
        const item: ICompetition = {
            id: id,
            year: 0,
            name: "",
            startDate: null,
            finishDate: null,
            siteUrl: "",
            logoUrl: ""
        }
        competitionData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.year = Number(i["year"]);
                item.name = i["name"];
                item.startDate = new Date(i["startDate"]);
                item.finishDate = new Date(i["finishDate"]);
                item.siteUrl = i["siteUrl"];
                item.logoUrl = i["logoUrl"];
            }
        });
        return item;
    };

    public static getDivisionById(id: string): IDivision {
        const item: IDivision = {
            id: id,
            competitionId: "",
            gender: "",
            age: "",
            name: "",
            siteUrl: ""
        }
        divisionData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.competitionId = i["competitionId"];
                item.gender = i["gender"];
                item.age = i["age"];
                item.name = i["name"];
                item.siteUrl = i["siteUrl"];
            }
        });
        return item;
    };

    public static getBracketById(id: string): IBracket {
        const item: IBracket = {
            id: id,
            divisionId: "",
            name: "",
            showStandings: false
        }
        bracketData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.divisionId = i["divisionId"];
                item.name = i["name"];
                item.showStandings = i["showStandings"];
            }
        });
        return item;
    };

    public static getOrganizationById(id: string): IOrganization {
        const item: IOrganization = {
            id: id,
            name: "",
            location: "",
            siteUrl: "",
            badgeUrl: ""
        };
        organizationData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.name = i["name"];
                item.location = i["location"];
                item.siteUrl = i["siteUrl"];
                item.badgeUrl = i["badgeUrl"];
            }
        });
        return item;
    };

    public static getOrganizationByTeamId(id: string): IOrganization {
        const team: ITeam = this.getTeamById(id);
        return this.getOrganizationById(team.organizationId);
    };

    public static getTeamById(id: string): ITeam {
        const item: ITeam = {
            id: id,
            previousId: "",
            organizationId: "",
            name: "",
            code: "",
            siteUrl: "",
            rankingUrl: ""
        };
        teamData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.previousId = i["previousId"];
                item.organizationId = i["organizationId"];
                item.name = i["name"];
                item.code = i["code"];
                item.siteUrl = i["siteUrl"];
                item.rankingUrl = i["rankingUrl"];
            }
        });
        return item;
    };

    public static getCompetitionByDivisionId(id: string): ICompetition {
        const division: IDivision = this.getDivisionById(id);
        return this.getCompetitionById(division.competitionId);
    };

    public static getPlayerById(id: string): IPlayer {
        let item: IPlayer = {
            id: id,
            firstName: "",
            lastName: "",
            dob: null,
            pictureUrl: ""
        };
        playerData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.firstName = i["firstName"];
                item.lastName = i["lastName"];
                item.pictureUrl = i["pictureUrl"];
                if (i["dob"] !== null) {
                    item.dob = new Date(i["dob"]);
                }
                return item;
            }
        });
        return item;
    };

    public static getLocationById(id: string): ILocation {
        let item: ILocation = {
            id: id,
            name: "",
            field: "",
            address: "",
            cityStateZip: "",
            homeOrganizationId: "",
            mapUrl: ""
        };
        locationData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                item.name = i["name"];
                //item.field = i["field"];
                item.address = i["address"];
                item.cityStateZip = i["cityStateZip"];
                item.homeOrganizationId = i["homeOrganizationId"];
                item.mapUrl = i["mapUrl"];
            }
        });
        return item;
    };

};

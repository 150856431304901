import IOrganizationService from '../services/IOrganizationService';
import OrganizationRepository from '../repos/organizationRepository';

export default class OrganizationProvider {

    public static getService(): IOrganizationService {
        const repo: OrganizationRepository = new OrganizationRepository();
        return repo;
    }

}
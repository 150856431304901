import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ICompetition from '../../models/ICompetition';
import IDivision from '../../models/IDivision';
import IBreadcrumb from '../../models/IBreadcrumb';

import ICompetitionService from '../../services/ICompetitionService';
import CompetitionProvider from '../../providers/competitionProvider';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

import Breadcrumbs from '../../components/breadcrumbs';

interface IDivisionProps {

}

function Divisions(props: IDivisionProps) {

    const competitionService: ICompetitionService = CompetitionProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const competitionId: string = urlRoutes[urlRoutes.length - 1];

    //const sessionService: ISessionService = SessionProvider.getService();
    //sessionService.setItem("competitionId", competitionId);

    const [loading, setLoading] = useState<boolean>(true);
    const [competition, setCompetition] = useState<ICompetition>();
    const [competitionName, setCompetitionName] = useState<string>("");
    const [divisions, setDivisions] = useState<IDivision[]>([]);
    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Competition"
    });

    const onLoad = () => {
        setLoading(false);
        competitionService.getById(competitionId)
            .then((response: ICompetition) => {
                setCompetition(response);
                setCompetitionName(response.name + " - " + response.year);
                //sessionService.setItem("competitionName", response.name + " - " + response.year);

                setFavorite(
                    favoriteService.init(window.location, response.name + " - " + response.year, favorite.type)
                );

                competitionService.getDivisionsById(response.id)
                    .then((response: IDivision[]) => {
                        setDivisions(response);
                        setLoading(false);
                    });
            });
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={competitionName} />
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={competition?.logoUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{competitionName}</span>
                        </div>
                        <div className="table-responsive">
                            <table id="competition-table" className="table shadow table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-start">Name</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Site</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        divisions?.map(d => {
                                            return (
                                                <tr key={d.id}>
                                                    <td className="text-start">
                                                        <Link to={"/Competition/Division/Detail/" + d.id} style={{ textDecoration: "none" }}>
                                                            <span>{d.name}</span>
                                                        </Link>
                                                    </td>
                                                    <td>{d.age}</td>
                                                    <td>{d.gender}</td>
                                                    <td>
                                                        {
                                                            d.siteUrl !== "" ?
                                                                <a href={d.siteUrl} target="_blank" style={{ textDecoration: "none" }} title="">Link</a>
                                                                :
                                                                <></>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Divisions;

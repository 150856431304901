import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';

import IMatch from '../../models/IMatch';
import IMatchResult from '../../models/IMatchResult';
import IMatchService from '../../services/IMatchService';
import MatchProvider from '../../providers/matchProvider';

import './match.css';

interface IMatchProps {
    
}

function Match(props: IMatchProps) {

    const videoSrc: string = "../../highlights/Fram_SlammersHB_goal_4.mp4";

    const onLoad = () => {

    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div>SoCal Fall League</div>
                </div>
                <div>
                    <div>Full Time</div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <img src="teamBadges/fram.png" height="80px" width="80px" />
                </div>
                <div className="align-self-center" style={{ fontSize: "36px" }}>
                    <span className="justify-content-center" style={{ width: "60px", display: "inline-flex" }}>
                        <span>2</span>
                    </span>
                    <span className="justify-content-center" style={{ marginLeft: "44px", marginRight: "44px" }}>-</span>
                    <span className="justify-content-center" style={{ width: "60px", display: "inline-flex" }}>
                        <span>1</span>
                    </span>
                </div>
                <div>
                    <img src="teamBadges/fram.png" height="80px" width="80px" />
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div>FRAM this is some more text wrap examples</div>
                </div>
                <div>
                    <div>Strikers FC This is a long team name sample</div>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div>Player 1</div>
                    <div>Player 1</div>
                </div>
                <div>
                    <i className="fa fa-futbol" title="Goals" />
                </div>
                <div>
                    <div>Player 1</div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3 d-none">
                <div>
                    <div>Player 1</div>
                    <div>Player 1</div>
                </div>
                <div>
                    <i className="fa fa-square" style={{ color: "yellow" }} title="Yellow Cards" />
                </div>
                <div>
                    <div>Player 1</div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3 d-none">
                <div>
                    <div>Player 1</div>
                    <div>Player 1</div>
                </div>
                <div>
                    <i className="fa fa-square" style={{ color: "red" }} title="Red Cards" />
                </div>
                <div>
                    <div>Player 1</div>
                </div>
            </div>
            <Tab.Container defaultActiveKey="highlights">
                <Card className="shadow">
                    <Card.Header>
                        <Nav variant="tabs" defaultActiveKey="highlights" justify>
                            <Nav.Item>
                                <Nav.Link eventKey="highlights">Highlights</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="roster">Rosters</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="stats">Stats</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <Tab.Content>
                            <Tab.Pane eventKey="highlights">I am the highlights pane</Tab.Pane>
                            <Tab.Pane eventKey="roster">I am the rosters pane</Tab.Pane>
                            <Tab.Pane eventKey="stats">
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <img src="teamBadges/fram.png" height="24px" width="24px" />
                                        </span>
                                    </div>
                                    <div>
                                        <span>TEAM STATS</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <img src="teamBadges/fram.png" height="24px" width="24px" />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Shots</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Shots on target</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Fouls</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Yellow Cards</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Red Cards</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Corners</span>
                                    </div>
                                    <div>
                                        <span className="justify-content-center" style={{ width: "32px", display: "inline-flex" }}>
                                            <span>2</span>
                                        </span>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Tab.Container>
        </div>
    );
}

export default Match;

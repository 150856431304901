import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IOrganization from '../../models/IOrganization';
import IOrganizationTeam from '../../models/IOrganizationTeam';
import ITeam from '../../models/ITeam';

import IOrganizationService from '../../services/IOrganizationService';
import OrganizationProvider from '../../providers/organizationProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

interface ISiteProps {
    id: string;
}

function Site(props: ISiteProps) {

    const organizationService: IOrganizationService = OrganizationProvider.getService();
    //const siteService: ISiteService = SiteProvider.getService(props.type);
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const [loading, setLoading] = useState<boolean>(true);
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    const [organizationTeams, setOrganizationTeams] = useState<IOrganizationTeam[]>([]);

    const defaultFavorite = {
        name: "All Clubs",
        url: "/Organization/List",
        type: "Clubs"
    };

    const [favorite, setFavorite] = useState<IFavorite>(defaultFavorite);

    const onLoad = () => {
        organizationService.getAll()
            .then((response: IOrganization[]) => {
                setOrganizations(response);
                setLoading(false);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div className="table-responsive">
                            <table id="team-table" className="table table-sm shadow table-hover caption-top">
                                <caption>Clubs</caption>
                                <thead>
                                    <tr>
                                        <th style={{ width: "40px" }}>&nbsp;</th>
                                        <th className="text-start">Name</th>
                                        <th className="text-start">Location</th>
                                        <th>Site</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        organizations.map(o => {
                                            return (
                                                <tr key={o.id}>
                                                    <td style={{ width: "40px" }}>
                                                        <img src={o.badgeUrl} height="24px" width="24px" alt="Club Badge" />
                                                    </td>
                                                    <td className="text-start">
                                                        <Link to={"/Organization/Team/List/" + o.id} style={{ textDecoration: "none" }}>
                                                            <span>{o.name}</span>
                                                        </Link>
                                                    </td>
                                                    <td className="text-start">{o.location}</td>
                                                    <td>
                                                        {
                                                            o.siteUrl !== "" ?
                                                                <a href={o.siteUrl} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }} title="">Link</a>
                                                                :
                                                                <></>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Site;

import ICookieService from '../services/ICookieService';
import CookieRepository from '../repos/cookieRepository';

export default class CookieProvider {

    public static getService(): ICookieService {
        const repo: CookieRepository = new CookieRepository();
        return repo;
    }

}
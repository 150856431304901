import React, { useRef, useState, useEffect, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import IMatch from '../models/IMatch';
import IMatchResult from '../models/IMatchResult';
import IMatchService from '../services/IMatchService';
import MatchProvider from '../providers/matchProvider';

import './matches.css';

interface IMatchesProps {

}

function Matches(props: IMatchesProps) {

    const matchService: IMatchService = MatchProvider.getService();

    const [matchResults, setMatchResults] = useState<IMatchResult[]>([]);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const onLoad = () => {
        matchService.getAll()
            .then((response: IMatchResult[]) => {
                setMatchResults(response);
                //setOrganizations(response);
            });
    };

    const isTomorrow = (date: Date) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (tomorrow.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }

    const renderMatches = () => {

        let matchDate: string = "";
        let newDate: boolean = false;
        return matchResults.map(m => {
            newDate = false;
            if (m.date.toLocaleDateString() !== matchDate) {
                matchDate = m.date.toLocaleDateString();
                newDate = true;
            }
            let homeWin: boolean = false;
            let awayWin: boolean = false;
            if (m.homeScore !== null && m.awayScore !== null) {
                homeWin = m.homeScore > m.awayScore;
                awayWin = m.awayScore > m.homeScore;
            }
            let matchTimeHeader: string = ""; // "FT", "Tomorrow", "Day, Month Day"
            if (homeWin || awayWin) {
                matchTimeHeader = "Final"
            }
            else {
                if (isTomorrow(m.date)) {
                    matchTimeHeader = "Tomorrow";
                }
                else {
                    matchTimeHeader = days[m.date.getDay()] + ", " + months[m.date.getMonth()] + " " + m.date.getDate();
                }
            }
            let matchTimeSubHeader: string = ""; // "Today", "hh:mm AM/PM", "Day, Month Day"
            matchTimeSubHeader = m.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            //const matchDateTime = m.date.toLocaleDateString() + " " + m.date.getHours() + ":" + m.date.getMinutes();
            const matchDateTime = m.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            //const matchDateTime = m.date.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short', hourCycle: 'h12' });
            return (
                <Fragment>
                    {
                        newDate ?
                            <div className="rowx p-0">
                                <div className="col bg-secondary fw-bold fs-6 p-3 text-white">
                                    <span>{matchDate}</span>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <div key={m.id} className="col p-0">
                        <div className="match border" style={{ padding: "16px", fontSize: "14px" }}>
                            <div style={{ marginBottom: "8px" }}>{m.label}</div>
                            <div className="d-flex">
                                <div className="flex-fill" style={{ textAlign: "left" }}>
                                    <div className={awayWin ? "d-flex align-content-center flex-nowrap match-winner" : "d-flex align-content-center flex-nowrap"} style={{ minHeight: "42px", borderRight: "1px solid grey" }}>
                                        <div className="align-self-center match-team-badge" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <img src={m.awayTeamBadgeUrl} height="24" width="24" />
                                        </div>
                                        <div className="align-self-center w-100 match-team-name">
                                            <a href="#" style={{ textDecoration: "none", color: "inherit" }}>{m.awayTeamName}</a>
                                        </div>
                                        <div className="align-self-center ms-auto text-end match-team-score" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <span style={{ paddingRight: "10px" }}>{m.awayScore === null ? "" : m.awayScore}</span>
                                            <i className={awayWin ? "fa fa-caret-left" : "fa"} style={{ width: "7px", marginRight: "-2px" }} />
                                        </div>
                                    </div>
                                    <div className={homeWin ? "d-flex align-content-center flex-nowrap match-winner" : "d-flex align-content-center flex-nowrap"} style={{ minHeight: "42px", borderRight: "1px solid grey" }}>
                                        <div className="align-self-center match-team-badge" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <img src={m.homeTeamBadgeUrl} height="24" width="24" />
                                        </div>
                                        <div className="align-self-center w-100 match-team-name">
                                            <a href="#" style={{ textDecoration: "none", color: "inherit" }}>{m.homeTeamName}</a>
                                        </div>
                                        <div className="align-self-center ms-auto text-end match-team-score" style={{ maxWidth: "40px", minWidth: "40px" }}>
                                            <span style={{ paddingRight: "10px" }}>{m.homeScore === null ? "" : m.homeScore}</span>
                                            <i className={homeWin ? "fa fa-caret-left" : "fa"} style={{ width: "7px", marginRight: "-2px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-fill align-self-center" style={{ textAlign: "center", minWidth: "100px", maxWidth: "100px", paddingLeft: "16px" }}>
                                    <div className="">{matchTimeHeader}</div>
                                    <div className="">{matchTimeSubHeader}</div>
                                    <div className="">
                                        {
                                            m.videoUrl !== "" ?
                                                <a href={m.videoUrl} target="_blank" style={{ textDecoration: "none", marginRight: "10px" }} title="Watch Game Replay"><i className="fa fa-video"></i></a>
                                                :
                                                <></>
                                        }
                                        <Link to={"/Match/" + m.id} style={{ textDecoration: "none" }} title="View Game Stats"><i className="fa fa-chart-bar"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        });
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            <div className="row" style={{ margin: "0" }}>
                {renderMatches()}
            </div>
        </div>
    );
}

export default Matches;
import React from 'react';
import './App.css';
import Container from './components/container';

interface IAppProps {
    basename: string
}

function App(props: IAppProps) {
    return (
        <div className="App">
            <Container basename={props.basename} />
        </div>
    );
}

export default App;

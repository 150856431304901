import React from 'react';

interface ITeamsProps {

}

function Teams(props: ITeamsProps) {
    return (
        <div style={{ backgroundColor: "white", marginTop: "20px", padding: "20px" }}>Here is where the teams are listed</div>
    );
}

export default Teams;
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './header';
import Nav from './nav';
import Routing from './routing';
import Footer from './footer';

interface IContainerProps {
    basename: string;
}

function Container(props: IContainerProps) {
    return (
        <div>
            <BrowserRouter basename={props.basename}>
                <Header />
                <Nav />
                <div className="container container-sm container-md container-lg" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Routing />
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default Container;
import IDivision from '../models/IDivision';
import IDivisionService from '../services/IDivisionService';
import IBracket from '../models/IBracket';
import ICompetition from '../models/ICompetition';
import ICompetitionDivisionResult from '../models/ICompetitionDivisionResult';
import ICompetitionBracketResult from '../models/ICompetitionBracketResult';
import IBracketStanding from '../models/IBracketStanding';
import ITeam from '../models/ITeam';
import IMatchResult from '../models/IMatchResult';

import DataRepository from './dataRepository';

import organizationData from '../data/organizations.json';
import competitionData from '../data/competitions.json';
import divisionData from '../data/divisions.json';
import bracketData from '../data/brackets.json';
import bracketTeamData from '../data/bracketTeams.json';
import teamData from '../data/teams.json';
import matchData from '../data/matches.json';

export default class DivisionRepository implements IDivisionService {

    public async getAll(): Promise<IDivision[]> {
        const items: IDivision[] = [];
        divisionData.forEach(i => {
            const item: IDivision = {
                id: i["id"],
                competitionId: i["competitionId"],
                gender: i["gender"],
                age: i["age"],
                name: i["name"],
                siteUrl: i["siteUrl"]
            };
            items.push(item);
        });
        return items;
    };

    public async getByCompetitionId(id: string): Promise<IDivision[]> {
        const items: IDivision[] = [];
        divisionData.forEach(i => {
            if (id.toUpperCase() === i["competitionId"].toUpperCase()) {
                const item: IDivision = {
                    id: i["id"],
                    competitionId: i["competitionId"],
                    gender: i["gender"],
                    age: i["age"],
                    name: i["name"],
                    siteUrl: i["siteUrl"]
                };
                items.push(item);
            }
        });
        return items;
    };

    /**
     * Performs the specified action for each element in an array.
     * @param id 
     */
    public async getById(id: string): Promise<IDivision> {
        return DataRepository.getDivisionById(id);
    };

    public async getBracketsById(id: string): Promise<IBracket[]> {
        const items: IBracket[] = [];

        bracketData.forEach(i => {
            if (id.toUpperCase() === i["divisionId"].toUpperCase()) {
                const item: IBracket = {
                    id: i["id"],
                    divisionId: i["divisionId"],
                    name: i["name"],
                    showStandings: i["showStandings"]
                }
                items.push(item);
            }
        });
        return items;
    };

    public async getResultsById(id: string): Promise<ICompetitionDivisionResult> {

        let competitionId: string = "";

        divisionData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                competitionId = i["competitionId"];
            }
        });

        const item: ICompetitionDivisionResult = {
            competition: DataRepository.getCompetitionById(competitionId),
            division: DataRepository.getDivisionById(id),
            brackets: []
        }
        const brackets: ICompetitionBracketResult[] = [];
        const bracketSort: any[] = [];
        bracketData.forEach(i => {
            if (id.toUpperCase() === i["divisionId"].toUpperCase()) {
                const bracket: ICompetitionBracketResult = {
                    id: i["id"],
                    name: i["name"],
                    showStandings: i["showStandings"],
                    teams: [],
                    matches: []
                }
                bracketSort.push({
                    name: i["name"],
                    index: Number(i["sort"])
                });
                const matchIds: string[] = [];
                bracketTeamData.forEach(j => {
                    if (bracket.id.toUpperCase() === j["bracketId"].toUpperCase()) {
                        const team: ITeam = DataRepository.getTeamById(j["teamId"]);
                        let teamLogoUrl: string = "teamBadges/unknown.png";
                        if (team.id === "") {
                            team.name = j["placeholderName"];
                        }
                        else {
                            teamLogoUrl = DivisionRepository.getOrganizationBadge(team.organizationId)
                        }
                        const bracketTeam: IBracketStanding = {
                            bracketTeamId: j["id"],
                            logoUrl: teamLogoUrl,
                            gamesPlayed: 0,
                            wins: 0,
                            loses: 0,
                            draws: 0,
                            goalsFor: 0,
                            goalsAgainst: 0,
                            goalDifferencial: 0,
                            points: 0,
                            lastResults: [],
                            /* ITeam Properties */
                            id: team.id,
                            previousId: team.previousId,
                            organizationId: team.organizationId,
                            name: team.name,
                            code: team.code,
                            siteUrl: team.siteUrl,
                            rankingUrl: team.rankingUrl
                        }
                        matchData.forEach(k => {
                            //home
                            let validMatch: boolean = false;
                            let homeScore: number = 0;
                            let awayScore: number = 0;
                            let win: boolean = false;
                            let loss: boolean = false;
                            let draw: boolean = false;
                            if (bracketTeam.bracketTeamId.toUpperCase() === k["homeTeamId"].toUpperCase()) {
                                if (k["homeScore"] !== null && k["awayScore"] !== null) {
                                    homeScore = Number(k["homeScore"]);
                                    awayScore = Number(k["awayScore"]);
                                    win = homeScore > awayScore;
                                    loss = homeScore < awayScore;
                                    draw = homeScore === awayScore;
                                    bracketTeam.goalsFor = bracketTeam.goalsFor + homeScore;
                                    bracketTeam.goalsAgainst = bracketTeam.goalsAgainst + awayScore;
                                }
                                validMatch = true;
                            }
                            //away
                            if (bracketTeam.bracketTeamId.toUpperCase() === k["awayTeamId"].toUpperCase()) {
                                if (k["homeScore"] !== null && k["awayScore"] !== null) {
                                    homeScore = Number(k["homeScore"]);
                                    awayScore = Number(k["awayScore"]);
                                    win = homeScore < awayScore;
                                    loss = homeScore > awayScore;
                                    draw = homeScore === awayScore;
                                    bracketTeam.goalsFor = bracketTeam.goalsFor + awayScore;
                                    bracketTeam.goalsAgainst = bracketTeam.goalsAgainst + homeScore;
                                }
                                validMatch = true;
                            }
                            if (win) {
                                bracketTeam.points = bracketTeam.points + 3;
                            }
                            if (draw) {
                                bracketTeam.points = bracketTeam.points + 1;
                            }
                            bracketTeam.wins = win ? bracketTeam.wins + 1 : bracketTeam.wins;
                            bracketTeam.loses = loss ? bracketTeam.loses + 1 : bracketTeam.loses;
                            bracketTeam.draws = draw ? bracketTeam.draws + 1 : bracketTeam.draws;
                            bracketTeam.gamesPlayed = win || loss || draw ? bracketTeam.gamesPlayed + 1 : bracketTeam.gamesPlayed;
                            if (validMatch) {
                                if (!matchIds.includes(k["id"])) {
                                    matchIds.push(k["id"]);
                                }
                            }
                        });
                        bracketTeam.goalDifferencial = bracketTeam.goalsFor - bracketTeam.goalsAgainst;
                        bracket.teams.push(bracketTeam);
                    }
                });
                const matches: IMatchResult[] = [];
                matchData.forEach(i => {
                    if (matchIds.includes(i["id"])) {
                        const match: IMatchResult = {
                            id: i["id"],
                            date: new Date(i["date"]),
                            label: i["label"],
                            homeTeamId: i["homeTeamId"],
                            homeTeamName: "",
                            homeTeamBadgeUrl: "",
                            homeScore: i["homeScore"] === null ? null : Number(i["homeScore"]),
                            homePenalties: i["homePenalties"] === null ? null : Number(i["homePenalties"]),
                            awayTeamId: i["awayTeamId"],
                            awayTeamName: "",
                            awayTeamBadgeUrl: "",
                            awayScore: i["awayScore"] === null ? null : Number(i["awayScore"]),
                            awayPenalties: i["awayPenalties"] === null ? null : Number(i["awayPenalties"]),
                            location: DataRepository.getLocationById(i["locationId"]),
                            field: i["field"],
                            state: i["state"],
                            videoUrl: i["videoUrl"]
                        };
                        let homeTeam: ITeam | null;
                        let awayTeam: ITeam | null;
                        bracketTeamData.forEach(j => {
                            if (match.homeTeamId.toUpperCase() === j["id"].toUpperCase()) {
                                homeTeam = DataRepository.getTeamById(j["teamId"]);
                                if (homeTeam.id === "") {
                                    const homeBracket = bracket.teams.find(t => t.bracketTeamId.toUpperCase() === j["id"].toUpperCase());
                                    if (homeBracket !== undefined) {
                                        homeTeam.name = homeBracket.name;
                                        match.homeTeamBadgeUrl = homeBracket.logoUrl;
                                    }
                                }
                                match.homeTeamName = homeTeam.name;
                            }
                            if (match.awayTeamId.toUpperCase() === j["id"].toUpperCase()) {
                                awayTeam = DataRepository.getTeamById(j["teamId"]);
                                if (awayTeam.id === "") {
                                    const awayBracket = bracket.teams.find(t => t.bracketTeamId.toUpperCase() === j["id"].toUpperCase());
                                    if (awayBracket !== undefined) {
                                        awayTeam.name = awayBracket.name;
                                        match.awayTeamBadgeUrl = awayBracket.logoUrl;
                                    }
                                }
                                match.awayTeamName = awayTeam.name;
                            }
                        });
                        organizationData.forEach(o => {
                            if (homeTeam !== null) {
                                if (homeTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                    match.homeTeamBadgeUrl = o["badgeUrl"];
                                }
                            }
                            if (awayTeam !== null) {
                                if (awayTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                    match.awayTeamBadgeUrl = o["badgeUrl"];
                                }
                            }
                        });
                        matches.push(match);
                        //bracket.matches.push(match);
                    }
                });
                bracket.matches = matches.sort((a, b) => {
                    const itemA: Date = a.date;
                    const itemB: Date = b.date;
                    if (itemA < itemB) {
                        return -1;
                    }
                    if (itemA > itemB) {
                        return 1;
                    }
                    return 0;
                });
                brackets.push(bracket);
            }
        });
        //console.log(bracketSort);
        item.brackets = brackets.sort((a, b) => {
            const itemA: number = bracketSort.find(x => x.name === a.name).index;
            const itemB: number = bracketSort.find(x => x.name === b.name).index;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });

        return item;
    };

    public async getResultByBracketId(id: string): Promise<ICompetitionDivisionResult> {

        let competitionId: string = "";
        let divisionId: string = "";

        bracketData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                divisionId = i["divisionId"];
            }
        });

        divisionData.forEach(i => {
            if (divisionId.toUpperCase() === i["id"].toUpperCase()) {
                competitionId = i["competitionId"];
            }
        });

        const item: ICompetitionDivisionResult = {
            competition: DataRepository.getCompetitionById(competitionId),
            division: DataRepository.getDivisionById(divisionId),
            brackets: []
        }
        const brackets: ICompetitionBracketResult[] = [];
        bracketData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                const bracket: ICompetitionBracketResult = {
                    id: i["id"],
                    name: i["name"],
                    showStandings: i["showStandings"],
                    teams: [],
                    matches: []
                }
                const matchIds: string[] = [];
                bracketTeamData.forEach(j => {
                    if (bracket.id.toUpperCase() === j["bracketId"].toUpperCase()) {
                        const team: ITeam = DataRepository.getTeamById(j["teamId"]);
                        let teamLogoUrl: string = "teamBadges/unknown.png";
                        if (team.id === "") {
                            team.name = j["placeholderName"];
                        }
                        else {
                            teamLogoUrl = DivisionRepository.getOrganizationBadge(team.organizationId)
                        }
                        const bracketTeam: IBracketStanding = {
                            bracketTeamId: j["id"],
                            logoUrl: teamLogoUrl,
                            gamesPlayed: 0,
                            wins: 0,
                            loses: 0,
                            draws: 0,
                            goalsFor: 0,
                            goalsAgainst: 0,
                            goalDifferencial: 0,
                            points: 0,
                            lastResults: [],
                            /* ITeam Properties */
                            id: team.id,
                            previousId: team.previousId,
                            organizationId: team.organizationId,
                            name: team.name,
                            code: team.code,
                            siteUrl: team.siteUrl,
                            rankingUrl: team.rankingUrl
                        }
                        matchData.forEach(k => {
                            //home
                            let validMatch: boolean = false;
                            let homeScore: number = 0;
                            let awayScore: number = 0;
                            let win: boolean = false;
                            let loss: boolean = false;
                            let draw: boolean = false;
                            if (bracketTeam.bracketTeamId.toUpperCase() === k["homeTeamId"].toUpperCase()) {
                                if (k["homeScore"] !== null && k["awayScore"] !== null) {
                                    homeScore = Number(k["homeScore"]);
                                    awayScore = Number(k["awayScore"]);
                                    win = homeScore > awayScore;
                                    loss = homeScore < awayScore;
                                    draw = homeScore === awayScore;
                                    bracketTeam.goalsFor = bracketTeam.goalsFor + homeScore;
                                    bracketTeam.goalsAgainst = bracketTeam.goalsAgainst + awayScore;
                                }
                                validMatch = true;
                            }
                            //away
                            if (bracketTeam.bracketTeamId.toUpperCase() === k["awayTeamId"].toUpperCase()) {
                                if (k["homeScore"] !== null && k["awayScore"] !== null) {
                                    homeScore = Number(k["homeScore"]);
                                    awayScore = Number(k["awayScore"]);
                                    win = homeScore < awayScore;
                                    loss = homeScore > awayScore;
                                    draw = homeScore === awayScore;
                                    bracketTeam.goalsFor = bracketTeam.goalsFor + awayScore;
                                    bracketTeam.goalsAgainst = bracketTeam.goalsAgainst + homeScore;
                                }
                                validMatch = true;
                            }
                            if (win) {
                                bracketTeam.points = bracketTeam.points + 3;
                            }
                            if (draw) {
                                bracketTeam.points = bracketTeam.points + 1;
                            }
                            bracketTeam.wins = win ? bracketTeam.wins + 1 : bracketTeam.wins;
                            bracketTeam.loses = loss ? bracketTeam.loses + 1 : bracketTeam.loses;
                            bracketTeam.draws = draw ? bracketTeam.draws + 1 : bracketTeam.draws;
                            bracketTeam.gamesPlayed = win || loss || draw ? bracketTeam.gamesPlayed + 1 : bracketTeam.gamesPlayed;
                            if (validMatch) {
                                if (!matchIds.includes(k["id"])) {
                                    matchIds.push(k["id"]);
                                }
                            }
                        });
                        bracketTeam.goalDifferencial = bracketTeam.goalsFor - bracketTeam.goalsAgainst;
                        bracket.teams.push(bracketTeam);
                    }
                });
                //alert(JSON.stringify(matchTeamData));
                const matches: IMatchResult[] = [];
                matchData.forEach(i => {
                    if (matchIds.includes(i["id"])) {
                        const match: IMatchResult = {
                            id: i["id"],
                            date: new Date(i["date"]),
                            label: i["label"],
                            homeTeamId: i["homeTeamId"],
                            homeTeamName: "",
                            homeTeamBadgeUrl: "",
                            homeScore: i["homeScore"] === null ? null : Number(i["homeScore"]),
                            homePenalties: i["homePenalties"] === null ? null : Number(i["homePenalties"]),
                            awayTeamId: i["awayTeamId"],
                            awayTeamName: "",
                            awayTeamBadgeUrl: "",
                            awayScore: i["awayScore"] === null ? null : Number(i["awayScore"]),
                            awayPenalties: i["awayPenalties"] === null ? null : Number(i["awayPenalties"]),
                            location: DataRepository.getLocationById(i["locationId"]),
                            field: i["field"],
                            state: i["state"],
                            videoUrl: i["videoUrl"]
                        };
                        let homeTeam: ITeam | null;
                        let awayTeam: ITeam | null;
                        bracketTeamData.forEach(j => {
                            if (match.homeTeamId.toUpperCase() === j["id"].toUpperCase()) {
                                homeTeam = DataRepository.getTeamById(j["teamId"]);
                                if (homeTeam.id === "") {
                                    const homeBracket = bracket.teams.find(t => t.bracketTeamId.toUpperCase() === j["id"].toUpperCase());
                                    if (homeBracket !== undefined) {
                                        homeTeam.name = homeBracket.name;
                                        match.homeTeamBadgeUrl = homeBracket.logoUrl;
                                    }
                                }
                                match.homeTeamName = homeTeam.name;
                            }
                            if (match.awayTeamId.toUpperCase() === j["id"].toUpperCase()) {
                                awayTeam = DataRepository.getTeamById(j["teamId"]);
                                if (awayTeam.id === "") {
                                    const awayBracket = bracket.teams.find(t => t.bracketTeamId.toUpperCase() === j["id"].toUpperCase());
                                    if (awayBracket !== undefined) {
                                        awayTeam.name = awayBracket.name;
                                        match.awayTeamBadgeUrl = awayBracket.logoUrl;
                                    }
                                }
                                match.awayTeamName = awayTeam.name;
                            }
                        });
                        organizationData.forEach(o => {
                            if (homeTeam !== null) {
                                if (homeTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                    match.homeTeamBadgeUrl = o["badgeUrl"];
                                }
                            }
                            if (awayTeam !== null) {
                                if (awayTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                    match.awayTeamBadgeUrl = o["badgeUrl"];
                                }
                            }
                        });
                        matches.push(match);
                        //bracket.matches.push(match);
                    }
                });
                bracket.matches = matches.sort((a, b) => {
                    const itemA: Date = a.date;
                    const itemB: Date = b.date;
                    if (itemA < itemB) {
                        return -1;
                    }
                    if (itemA > itemB) {
                        return 1;
                    }
                    return 0;
                });
                brackets.push(bracket);
            }
        });
        item.brackets = brackets.sort((a, b) => {
            const itemA: string = a.name;
            const itemB: string = b.name;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });

        return item;
    };

    private static getOrganizationBadge(id: string): string {
        let badgeUrl: string = "";
        organizationData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                badgeUrl = i["badgeUrl"];
            }
        });
        return badgeUrl;
    }

}

//import ITeamRoster from '../models/ITeamRoster';
//import IPlayer from '../models/IPlayer';

import ISiteService from '../services/ISiteService';
import IRoutes from '../models/IRoutes';
import IBreadcrumb from '../models/IBreadcrumb';
import ICompetition from '../models/ICompetition';
import ITeam from '../models/ITeam';
import IDivision from '../models/IDivision';
import IOrganization from '../models/IOrganization';

import DataRepository from './dataRepository';

//import teamRosterData from '../data/teamRoster.json';
//import playerData from '../data/players.json';
import bracketData from '../data/brackets.json';
import bracketTeamData from '../data/bracketTeams.json';
import competitionData from '../data/competitions.json';
import divisionData from '../data/divisions.json';
import teamData from '../data/teams.json';

export default class SiteRepository implements ISiteService {

    public getRoutes(location: Location): IRoutes {
        const url = new URL(location.href);
        const urlRoutes = url.pathname.split('/');
        const routes: IRoutes = {
            site: "",
            view: "",
            id: ""
        }
        urlRoutes.forEach((r, i) => {
            switch (i) {
                case 0: // Always Empty
                    break;
                case 1: // Site
                    routes.site = r;
                    break;
                case 2: // View
                    routes.view = r;
                    break;
                case 3: // Id
                    routes.id = r;
                    break;
            }
        });
        return routes;
    };

    public getBreadcrumbs(location: Location): IBreadcrumb[] {
        const items: IBreadcrumb[] = [];

        const url: URL = new URL(location.href);
        const urlRoutes = url.pathname.split('/');

        if (url.pathname.substring(0, 24) === "/Organization/Team/List/") {
            items.push({
                name: "Clubs",
                path: "/Organization/List",
                obj: null
            });
        }
        if (url.pathname.substring(0, 26) === "/Organization/Team/Detail/") {
            items.push({
                name: "Clubs",
                path: "/Organization/List",
                obj: null
            });
            const teamId: string = urlRoutes[urlRoutes.length - 1];
            const organization: IOrganization = DataRepository.getOrganizationByTeamId(teamId);
            items.push({
                name: organization.name + " - " + organization.location,
                path: "/Organization/Team/List/" + organization.id,
                obj: null
            });
        }
        if (url.pathname.substring(0, 29) === "/Competition/Division/Detail/") {
            const divisionId: string = urlRoutes[urlRoutes.length - 1];

            const competition: ICompetition = DataRepository.getCompetitionByDivisionId(divisionId);

            //<Link to={"/Competition/List"}>Competitions</Link> | <span>{competitionName}</span>
            items.push({
                name: "Competitions",
                path: "/Competition/List",
                obj: null
            });
            // getDivisionId
            items.push({
                name: competition.name + " - " + competition.year,
                path: "/Competition/Division/List/" + competition.id,
                obj: competition
            });
        }
        if (url.pathname.substring(0, 27) === "/Competition/Division/List/") {
            items.push({
                name: "Competitions",
                path: "/Competition/List",
                obj: null
            });
        }
        if (url.pathname.substring(0, 28) === "/Competition/Bracket/Detail/") {
            items.push({
                name: "Competitions",
                path: "/Competition/List",
                obj: null
            });

            const bracketId: string = urlRoutes[urlRoutes.length - 1];
            const bracket = DataRepository.getBracketById(bracketId);
            bracketData.forEach(i => {
                if (bracketId.toUpperCase() === i["id"].toUpperCase()) {
                    const divisionId: string = i["divisionId"];
                    const competition: ICompetition = DataRepository.getCompetitionByDivisionId(divisionId);

                    items.push({
                        name: competition.name + " - " + competition.year,
                        path: "/Competition/Division/List/" + competition.id,
                        obj: competition
                    });

                    const division: IDivision = DataRepository.getDivisionById(divisionId);
                    items.push({
                        name: division.name,
                        path: "/Competition/Division/Detail/" + division.id,
                        obj: division
                    });
                }
            });

        }
        if (url.pathname.substring(0, 25) === "/Competition/Team/Detail/") {

            items.push({
                name: "Competitions",
                path: "/Competition/List",
                obj: null
            });

            const bracketTeamId: string = urlRoutes[urlRoutes.length - 1];
            bracketTeamData.forEach(i => {
                if (bracketTeamId.toUpperCase() == i["id"].toUpperCase()) {
                    const bracketId: string = i["bracketId"];
                    const bracket = DataRepository.getBracketById(bracketId);
                    bracketData.forEach(j => {
                        if (bracketId.toUpperCase() === j["id"].toUpperCase()) {
                            const divisionId: string = j["divisionId"];
                            const competition: ICompetition = DataRepository.getCompetitionByDivisionId(divisionId);

                            items.push({
                                name: competition.name + " - " + competition.year,
                                path: "/Competition/Division/List/" + competition.id,
                                obj: competition
                            });

                            const division: IDivision = DataRepository.getDivisionById(divisionId);
                            items.push({
                                name: division.name,
                                path: "/Competition/Division/Detail/" + division.id,
                                obj: division
                            });

                            items.push({
                                name: bracket.name,
                                path: "/Competition/Bracket/Detail/" + bracket.id,
                                obj: bracket
                            });
                        }
                    });
                }
            });
        }

        return items;
    }

}

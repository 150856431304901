import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Matches from './matches';

import IDivision from '../../models/IDivision';
import IBracket from '../../models/IBracket';
import ICompetitionDivisionResult from '../../models/ICompetitionDivisionResult';
import ICompetitionBracketResult from '../../models/ICompetitionBracketResult';

import IDivisionService from '../../services/IDivisionService';
import DivisionProvider from '../../providers/divisionProvider';

import ISessionService from '../../services/ISessionService';
import SessionProvider from '../../providers/sessionProvider';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

import './division.css';
import Breadcrumbs from '../../components/breadcrumbs';

interface IDivisionProps {

}

function Division(props: IDivisionProps) {

    const divisionService: IDivisionService = DivisionProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const divisionId: string = urlRoutes[urlRoutes.length - 1];

    const [loading, setLoading] = useState<boolean>(true);

    const [competitionDivision, setCompetitionDivision] = useState<ICompetitionDivisionResult>();

    const [division, setDivision] = useState<IDivision>();
    const [divisionName, setDivisionName] = useState<string>("");
    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Competition Division"
    });

    //const breadcrumbs: IBreadcrumb[] = siteService.getBreadcrumbs(window.location);

    const onLoad = () => {

        //divisionService.getById(divisionId)
        //    .then((response: IDivision) => {
        //        console.log(response);
        //        setDivision(response);
        //        setDivisionName(response.name);
        //        sessionService.setItem("divisionName", response.name);
        //        setFavorite(
        //            favoriteService.init(window.location, competitionName + " - " + response.name, "Competition Division")
        //        );

        //        divisionService.getBracketsById(divisionId)
        //            .then((response: IBracket[]) => {
        //                setBrackets(response);
        //                setLoading(false);
        //            });
        //    });

        divisionService.getResultsById(divisionId)
            .then((response: ICompetitionDivisionResult) => {

                setCompetitionDivision(response);
                setDivision(response.division);
                setDivisionName(response.division.name);

                setFavorite(
                    favoriteService.init(window.location, response.competition.name + " - " + response.competition.year + " | " + response.division.name, favorite.type)
                );

                setLoading(false);
                //console.log(response);
            });
    }

    const toggleBracket = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (e.currentTarget !== undefined) {
            const target: HTMLSpanElement = e.currentTarget;
            const image: HTMLImageElement | null = target.querySelector<HTMLImageElement>(".card-toggle");
            const card: HTMLDivElement | null = target.closest<HTMLDivElement>(".card");
            if (card !== null) {
                if (card.classList.contains("card-collapse")) {
                    card.classList.remove("card-collapse");
                }
                else {
                    card.classList.add("card-collapse");
                }
                const cardBody: HTMLDivElement | null = card.querySelector<HTMLDivElement>(".card-body");
                if (cardBody !== null) {
                    if (cardBody.classList.contains("card-body-collapse")) {
                        cardBody.classList.remove("card-body-collapse");
                    }
                    else {
                        cardBody.classList.add("card-body-collapse");
                    }
                }
            }
        }
    }

    const showAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        const target: HTMLElement = e.currentTarget;
        if (target !== undefined) {
            const parent = target.closest(".division-match-container");
            if (parent !== null) {
                parent.querySelectorAll(".match-row .match-list-collapse .match-list-toggle").forEach(i => {
                    const item: HTMLElement = i as HTMLElement;
                    item.click();
                });
            }
        }
    }

    const closeAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        const target: HTMLElement = e.currentTarget;
        if (target !== undefined) {
            const parent = target.closest(".division-match-container");
            if (parent !== null) {
                parent.querySelectorAll(".match-row .col:not(.match-list-collapse) .match-list-toggle").forEach(i => {
                    const item: HTMLElement = i as HTMLElement;
                    item.click();
                });
            }
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={divisionName} />
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={competitionDivision?.competition.logoUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{divisionName}</span>
                        </div>
                        {
                            competitionDivision?.brackets.map((b, i) => {
                                return (
                                    <div key={b.id} className={competitionDivision?.brackets.length > 1 ? "card shadow card-collapse" : "card shadow"}>
                                        <div className="card-header" style={{ display: "flex" }}>
                                            <span className="card-toggle" onClick={toggleBracket}>
                                                <i className="fa fa-caret-down me-2" />
                                                <span>{b.name}</span>
                                            </span>
                                            <span style={{ display: "flex", marginLeft: "auto" }}>
                                                <Link to={"/Competition/Bracket/Detail/" + b.id} title="Goto Bracket View">Link</Link>
                                            </span>
                                        </div>
                                        <div className={competitionDivision?.brackets.length > 1 ? "card-body card-body-collapse" : "card-body"}>
                                            <div className={b.showStandings ? "table-responsive" : "d-none"}>
                                                <table id="competition-table" className="table shadow table-sm table-hover caption-top">
                                                    <caption>Standings</caption>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "40px" }}></th>
                                                            <th className="text-start">Name</th>
                                                            <th title="Matches Played" style={{ width: "34px" }}>MP</th>
                                                            <th title="Wins" style={{ width: "34px" }}>W</th>
                                                            <th title="Draws" style={{ width: "34px" }}>D</th>
                                                            <th title="Loses" style={{ width: "34px" }}>L</th>
                                                            <th title="Goals For" style={{ width: "34px" }}>GF</th>
                                                            <th title="Goals Against" style={{ width: "34px" }}>GA</th>
                                                            <th title="Goal Differential" style={{ width: "34px" }}>GD</th>
                                                            <th title="Points" style={{ width: "34px" }}>PTS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            b.teams.length === 0 ?
                                                                <tr><td className="text-center" colSpan={10}>No Teams Found</td></tr>
                                                                :
                                                                b.teams.map((t, i) => {
                                                                    return (
                                                                        <tr key={t.id} className="hidable">
                                                                            <td style={{ width: "40px" }}>
                                                                                <img src={t.logoUrl} width="24px" height="24px" />
                                                                            </td>
                                                                            <td className="text-start">
                                                                                <Link to={"/Competition/Team/Detail/" + t.bracketTeamId}>{t.name}</Link>
                                                                            </td>
                                                                            <td>{t.gamesPlayed}</td>
                                                                            <td>{t.wins}</td>
                                                                            <td>{t.draws}</td>
                                                                            <td>{t.loses}</td>
                                                                            <td>{t.goalsFor}</td>
                                                                            <td>{t.goalsAgainst}</td>
                                                                            <td>{t.goalDifferencial}</td>
                                                                            <td>{t.points}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="division-match-container">
                                                <div style={{ color: "#6c757d", marginBottom: "8px" }}>
                                                    <span>Matches</span>
                                                    <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={closeAllMatches}>Close All</span>
                                                    <span style={{ marginLeft: "8px", float: "right" }}>|</span>
                                                    <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={showAllMatches}>Open All</span>
                                                </div>
                                                <Matches matchResults={b.matches}></Matches>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>
            }
        </div>
    );
}

export default Division;

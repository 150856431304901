import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IBreadcrumb from '../models/IBreadcrumb';
import ISiteService from '../services/ISiteService';

interface IBreadcrumbsProps {
    service: ISiteService,
    currentBreadcrumb: string
}

function Breadcrumbs(props: IBreadcrumbsProps) {

    const breadcrumbs: IBreadcrumb[] = props.service.getBreadcrumbs(window.location);
    const [breadcrumbName, setBreadcrumbName] = useState<string>(props.currentBreadcrumb);

    useEffect(() => {
        setBreadcrumbName(props.currentBreadcrumb);
    }, [props.currentBreadcrumb]);

    return (
        <div>
            {
                breadcrumbs.map((b, i) => {
                    return (
                        <Link key={i} to={b.path} className="site-bread-crumb">{b.name}</Link>
                    )
                })
            }
            <span>{breadcrumbName}</span>
        </div>
    )
}

export default Breadcrumbs;

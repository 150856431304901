import ICompetitionService from '../services/ICompetitionService';
import CompetitionRepository from '../repos/competitionRepository';

export default class CompetitionProvider {

    public static getService(): ICompetitionService {
        const repo: CompetitionRepository = new CompetitionRepository();
        return repo;
    }

}
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IFavorite from '../models/IFavorite';
import IFavoriteService from '../services/IFavoriteService';

interface IFavoriteProps {
    service: IFavoriteService,
    favorite: IFavorite
}

function Favorite(props: IFavoriteProps) {

    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [favorite, setFavorite] = useState<IFavorite>(props.favorite);

    const favoriteOnClick = (e: React.MouseEvent<HTMLImageElement>) => {
        const target: HTMLImageElement = e.currentTarget;
        if (target.classList.contains("fa-regular")) {
            if (favorite !== undefined) {
                props.service.add(favorite);
                setIsFavorite(true);
            }
        }
        else {
            if (favorite !== undefined) {
                props.service.remove(favorite.name);
                setIsFavorite(false);
            }
        }
    }

    useEffect(() => {
        setFavorite(props.favorite);
        props.service.exists(props.favorite.name)
            .then((response: boolean) => {
                setIsFavorite(response);
            });
    }, [props.favorite]);


    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: "0" }}>
                <i className={isFavorite ? "fa-solid fa-star text-primary" : "fa-regular fa-star text-primary"} style={{ cursor: "pointer" }} onClick={favoriteOnClick} title={isFavorite ? "Marked as favorite" : "Click here to mark as a favorite"} />
            </div>
        </div>
    )
}

export default Favorite;
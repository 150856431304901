import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ICompetition from '../../models/ICompetition';

import ICompetitionService from '../../services/ICompetitionService';
import CompetitionProvider from '../../providers/competitionProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

interface ISiteProps {

}

function Site(props: ISiteProps) {

    const competitionService: ICompetitionService = CompetitionProvider.getService();
    //const siteService: ISiteService = SiteProvider.getService(props.type);
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const [loading, setLoading] = useState<boolean>(true);
    const [competitions, setCompetitions] = useState<ICompetition[]>([]);
    const [favorite, setFavorite] = useState<IFavorite>({
        name: "All Competitions",
        url: "/Competition/List",
        type: "Competitions"
    });

    const onLoad = () => {
        competitionService.getAll()
            .then((response: ICompetition[]) => {
                setCompetitions(response);
                setLoading(false);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <table className="table shadow table-sm table-hover caption-top">
                            <caption>Competitions</caption>
                            <thead>
                                <tr>
                                    <th style={{ width: "40px" }}>&nbsp;</th>
                                    <th className="text-start">Name</th>
                                    <th>Start</th>
                                    <th>Finish</th>
                                    <th>Site</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    competitions.map(c => {
                                        return (
                                            <tr key={c.id}>
                                                <td style={{ width: "40px" }}>
                                                    <img src={c.logoUrl} height="24px" width="24px" />
                                                </td>
                                                <td className="text-start">
                                                    <Link to={"/Competition/Division/List/" + c.id} style={{ textDecoration: "none" }}>
                                                        <span>{c.name}</span>
                                                    </Link>
                                                </td>
                                                <td>{c.startDate === null ? "" : c.startDate.toLocaleDateString()}</td>
                                                <td>{c.finishDate === null ? "" : c.finishDate.toLocaleDateString()}</td>
                                                <td>
                                                    {
                                                        c.siteUrl !== "" ?
                                                            <a href={c.siteUrl} target="_blank" style={{ textDecoration: "none" }} title="">Link</a>
                                                            :
                                                            <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
}

export default Site;

import React, { useRef, useState, useEffect } from 'react';
import IOrganization from '../models/IOrganization';
import ITeam from '../models/ITeam';
import IOrganizationService from '../services/IOrganizationService';
import OrganizationProvider from '../providers/organizationProvider';
import IPlayer from '../models/IPlayer';
import IPlayerService from '../services/IPlayerService';
import PlayerProvider from '../providers/playerProvider';
import ITeamRoster from '../models/ITeamRoster';

interface IPlayersProps {

}

function Players(props: IPlayersProps) {

    const organizationService: IOrganizationService = OrganizationProvider.getService();
    const playerService: IPlayerService = PlayerProvider.getService();

    const [loading, setLoading] = useState<boolean>(true);
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    const [teams, setTeams] = useState<ITeam[]>([]);
    const [roster, setRoster] = useState<ITeamRoster[]>([]);

    const clubSelectRef = useRef<HTMLSelectElement>(null);
    const teamSelectRef = useRef<HTMLSelectElement>(null);

    const onLoad = () => {
        organizationService.getAll()
            .then((response: IOrganization[]) => {
                setOrganizations(response);
            });
    //    playerService.getAll()
    //        .then((response: IPlayer[]) => {
    //            setPlayers(response);
    //        });
    }

    useEffect(() => {
        onLoad();
    }, []);

    const onClubChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const club: string = e.currentTarget.value;
        console.log(club);
        organizationService.getTeamsById(club)
            .then((response: ITeam[]) => {
                setTeams(response);
            });
        setTeams([]);
        if (teamSelectRef.current) {
            teamSelectRef.current.value = "";
        }
        setRoster([]);
    }

    //const uniqueKey(limit: number): string => {
    //    const characters: string = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //    const randstring: string = '';
    //    const i: number = 0;
    //    for (i; i < limit; i++) {
    //        randstring.= characters[Math.random(0, strlen(characters))];
    //    }
    //    return randstring;
    //};

    const onTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const team: string = e.currentTarget.value;
        playerService.getTeamRosterById(team)
            .then((response: ITeamRoster[]) => {
                //response.forEach(r => {

                //});
                setRoster(response);
            });
    }

    return (
        <div style={{ backgroundColor: "white", marginTop: "20px", padding: "20px", borderRadius: "5px" }}>
            <div className="row mb-3">
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Club</label>
                        <select ref={clubSelectRef} className="form-select" onChange={onClubChange}>
                            <option value="" disabled selected={true} >... Select A Club</option>
                            {
                                organizations.map(o => {
                                    return <option value={o.id}>{o.name} | {o.location}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Team</label>
                        <select ref={teamSelectRef} className="form-select" onChange={onTeamChange}>
                            <option value="" disabled selected={true}>... Select A Team</option>
                            {
                                teams.map(o => {
                                    return <option value={o.id}>{o.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    roster.map(p => {
                        const pictureUrl: string = p.pictureUrl === "" ? "/profiles/unknown_user_profile_male.png" : p.pictureUrl;
                        return (
                            <div className="col">
                                <div className="card mb-3" style={{ width: "96px" }}>
                                    <img src={pictureUrl} className="card-img-top" height="96px" width="96px" alt="..." />
                                    <div className="card-body" style={{ padding: "4px", height: "96px" }}>
                                        <p className="card-text text-center" style={{ fontSize: "12px" }} >
                                            <div>{p.lastName}, {p.firstName}</div>
                                            <div>{p.position} #{p.number}</div>
                                            <div className="d-none">{p.dob === null ? "unknown" : p.dob.toLocaleDateString()}</div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Players;
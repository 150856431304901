import IPlayerService from '../services/IPlayerService';
import PlayerRepository from '../repos/playerRepository';

export default class PlayerProvider {

    public static getService(): IPlayerService {
        const repo: PlayerRepository = new PlayerRepository();
        return repo;
    }

}
import ICompetition from '../models/ICompetition';
import IDivision from '../models/IDivision';
import ICompetitionTeamStats from '../models/ICompetitionTeamStats';
import ICompetitionPlayerStats from '../models/ICompetitionPlayerStats';
import ICompetitionService from '../services/ICompetitionService';
import ITeam from '../models/ITeam';
import IOrganization from '../models/IOrganization';
import ICompetitionGoaliePlayerStats from '../models/ICompetitionGoaliePlayerStats';
import ICompetitionFieldPlayerStats from '../models/ICompetitionFieldPlayerStats';
import IPlayer from '../models/IPlayer';
import IMatchResult from '../models/IMatchResult';

import DataRepository from './dataRepository';

import bracketTeamData from '../data/bracketTeams.json';
import competitionData from '../data/competitions.json';
import divisionData from '../data/divisions.json';
import matchData from '../data/matches.json';
import matchRosterData from '../data/matchRoster.json';
import matchHighlightData from '../data/matchHighlight.json';
import organizationData from '../data/organizations.json';

export default class CompetitionRepository implements ICompetitionService {

    private defaultBadgeUrl: string = "teamBadges/unknown.png";

    public async getYears(): Promise<number[]> {
        const items: number[] = [];
        competitionData.forEach(i => {
            const item: ICompetition = {
                id: i["id"],
                year: Number(i["year"]),
                name: i["name"],
                startDate: new Date(i["startDate"]),
                finishDate: new Date(i["finishDate"]),
                siteUrl: i["siteUrl"],
                logoUrl: i["logoUrl"]
            };
            if (!items.includes(item.year)) {
                items.push(item.year);
            }
        });
        return items.sort((a, b) => { return a - b });
    }

    public async getAll(): Promise<ICompetition[]> {
        const items: ICompetition[] = [];
        competitionData.forEach(i => {
            const item: ICompetition = {
                id: i["id"],
                year: Number(i["year"]),
                name: i["name"],
                startDate: new Date(i["startDate"]),
                finishDate: new Date(i["finishDate"]),
                siteUrl: i["siteUrl"],
                logoUrl: i["logoUrl"]
            };
            items.push(item);
        });
        return items.sort((a, b) => {
            const itemA: Date = a.startDate === null ? new Date() : a.startDate;
            const itemB: Date = b.startDate === null ? new Date() : b.startDate;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });
    };

    public async getByYear(year: number): Promise<ICompetition[]> {
        const items: ICompetition[] = [];
        competitionData.forEach(i => {
            if (year === Number(i["year"])) {
                const item: ICompetition = {
                    id: i["id"],
                    year: Number(i["year"]),
                    name: i["name"],
                    startDate: new Date(i["startDate"]),
                    finishDate: new Date(i["finishDate"]),
                    siteUrl: i["siteUrl"],
                    logoUrl: i["logoUrl"]
                };
                items.push(item);
            }
        });
        return items.sort((a, b) => {
            const nameA: string = a.name.toUpperCase();
            const nameB: string = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };

    public async getById(id: string): Promise<ICompetition> {
        return DataRepository.getCompetitionById(id);
    };

    public async getDivisionsById(id: string): Promise<IDivision[]> {
        const items: IDivision[] = [];
        divisionData.forEach(i => {
            if (id.toUpperCase() === i["competitionId"].toUpperCase()) {
                const item: IDivision = {
                    id: i["id"],
                    competitionId: i["competitionId"],
                    gender: i["gender"],
                    age: i["age"],
                    name: i["name"],
                    siteUrl: i["siteUrl"]
                };
                items.push(item);
            }
        });
        return items.sort((a, b) => {
            const nameA: string = a.name.toUpperCase();
            const nameB: string = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };

    public async getTeamStatsByBracketTeamId(id: string): Promise<ICompetitionTeamStats> {

        let teamId: string = "";
        let homeTeamName: string = "";
        const bracketTeamId = id;
        bracketTeamData.forEach(i => {
            if (bracketTeamId.toUpperCase() === i["id"].toUpperCase()) {
                teamId = i["teamId"];
            }
        });

        const matches: string[] = [];
        const team: ITeam = DataRepository.getTeamById(teamId);
        const organization: IOrganization = DataRepository.getOrganizationByTeamId(teamId);
        const players: ICompetitionFieldPlayerStats[] = [];
        const goalies: ICompetitionGoaliePlayerStats[] = [];
        const matchResults: IMatchResult[] = [];

        const item: ICompetitionTeamStats = {
            team: team,
            badgeUrl: organization.badgeUrl,
            roster: {
                players: players,
                goalies: goalies
            },
            matches: matchResults
        };

        matchData.forEach(i => {
            if (bracketTeamId.toUpperCase() === i["homeTeamId"].toUpperCase() || bracketTeamId.toUpperCase() === i["awayTeamId"].toUpperCase()) {
                matches.push(i["id"]);
            }
        });

        matches.forEach(i => {

            matchRosterData.forEach(j => {
                if (i.toUpperCase() === j["matchId"].toUpperCase() && bracketTeamId.toUpperCase() === j["teamId"].toUpperCase()) {
                    const playerId: string = j["playerId"];
                    const player: IPlayer = DataRepository.getPlayerById(playerId);
                    const playerName: string = player.lastName + ", " + player.firstName;
                    const number: number = Number(j["number"]);
                    const position: string = j["position"];
                    const minutes: number = Number(j["minutes"]);
                    let matchesPlayed: number = 1;
                    switch (position) {
                        case "Goalie":
                            let goal = goalies.find(g => g["playerId"].toUpperCase() === playerId.toUpperCase());
                            if (goal !== undefined) {
                                console.log(goal);
                                goal.matchesPlayed = goal.matchesPlayed + matchesPlayed;
                            }
                            else {
                                goalies.push({
                                    playerId: playerId,
                                    number: number,
                                    name: playerName,
                                    position: position,
                                    matchesPlayed: matchesPlayed,
                                    shots: 0,
                                    saves: 0,
                                    savePercentage: 0,
                                    yellowCards: 0,
                                    redCards: 0
                                });
                            }
                            break;
                        default:
                            let play = players.find(p => p["playerId"].toUpperCase() === playerId.toUpperCase());
                            if (play !== undefined) {
                                play.matchesPlayed = play.matchesPlayed + 1;
                            }
                            else {
                                players.push({
                                    playerId: playerId,
                                    number: number,
                                    name: playerName,
                                    position: position,
                                    matchesPlayed: 1,
                                    shots: 0,
                                    goals: 0,
                                    assists: 0,
                                    yellowCards: 0,
                                    redCards: 0
                                });
                            }
                            break;
                    }
                }
            });

            matchHighlightData.forEach(j => {
                if (i.toUpperCase() === j["matchId"].toUpperCase() && bracketTeamId.toUpperCase() === j["teamId"].toUpperCase()) {
                    const playerId: string = j["playerId"];
                    const time: string = j["time"];
                    const type: string = j["type"];
                    let play = players.find(p => p["playerId"].toUpperCase() === playerId.toUpperCase());
                    if (play !== undefined) {
                        switch (type) {
                            case "Goal":
                                play.goals = play.goals + 1;
                                play.shots = play.shots + 1;
                                break;
                            case "Assist":
                                play.assists = play.assists + 1;
                                break;
                            case "Shot":
                                play.shots = play.shots + 1;
                                break;
                            case "Yellow Card":
                                play.yellowCards = play.yellowCards + 1;
                                break;
                            case "Red Card":
                                play.redCards = play.redCards + 1;
                                break;
                        }
                    }
                }
            });

            matchData.forEach(j => {
                if (i.toUpperCase() === j["id"].toUpperCase()) {
                    const item: IMatchResult = {
                        id: j["id"],
                        date: new Date(j["date"]),
                        label: j["label"],
                        homeTeamId: j["homeTeamId"],
                        homeTeamName: "",
                        homeTeamBadgeUrl: "",
                        homeScore: j["homeScore"] === null ? null : Number(j["homeScore"]),
                        homePenalties: j["homePenalties"] === null ? null : Number(j["homePenalties"]),
                        awayTeamId: j["awayTeamId"],
                        awayTeamName: "",
                        awayTeamBadgeUrl: "",
                        awayScore: j["awayScore"] === null ? null : Number(j["awayScore"]),
                        awayPenalties: j["awayPenalties"] === null ? null : Number(j["awayPenalties"]),
                        location: DataRepository.getLocationById(j["locationId"]),
                        field: j["field"],
                        state: j["state"],
                        videoUrl: j["videoUrl"]
                    };
                    let homeTeam: ITeam | null;
                    let awayTeam: ITeam | null;
                    bracketTeamData.forEach(k => {
                        if (item.homeTeamId.toUpperCase() === k["id"].toUpperCase()) {
                            homeTeam = DataRepository.getTeamById(k["teamId"]);
                            item.homeTeamName = homeTeam.name;
                        }
                        if (item.awayTeamId.toUpperCase() === k["id"].toUpperCase()) {
                            awayTeam = DataRepository.getTeamById(k["teamId"]);
                            item.awayTeamName = awayTeam.name;
                        }
                    });
                    organizationData.forEach(o => {
                        if (homeTeam !== null) {
                            if (homeTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                item.homeTeamBadgeUrl = o["badgeUrl"];
                            }
                        }
                        if (awayTeam !== null) {
                            if (awayTeam.organizationId.toUpperCase() === o.id.toUpperCase()) {
                                item.awayTeamBadgeUrl = o["badgeUrl"];
                            }
                        }
                    });
                    matchResults.push(item);
                }

            });

        });

        item.roster.players = players.sort((a, b) => {
            const itemA: number = a.number;
            const itemB: number = b.number;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });

        item.roster.goalies = goalies.sort((a, b) => {
            const itemA: number = a.number;
            const itemB: number = b.number;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });

        item.matches = matchResults.sort((a, b) => {
            const itemA: Date = a.date;
            const itemB: Date = b.date;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });

        return item;
    };
}

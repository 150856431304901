import React from 'react';

interface IHeaderProps {

}

function Header(props: IHeaderProps) {
    return (
        <div className="d-none">I am the Header</div>
    );
}

export default Header;
import ICookieService from '../services/ICookieService';
import ICookie from '../models/ICookie';

import CryptoJS from 'crypto-js';

export default class CookieRepository implements ICookieService {

    public getItem(prop: string): ICookie {
        const cookie: ICookie = {
            key: prop,
            value: null
        }
        const key: string = prop + "=";
        const pairs: string[] = decodeURIComponent(document.cookie).split(';');
        let i: number = 0;
        for (i; i < pairs.length; i++) {
            let cookieValue: string = pairs[i];
            while (cookieValue.charAt(0) == ' ') {
                cookieValue = cookieValue.substring(1, cookieValue.length);
            }
            if (cookieValue.indexOf(key) == 0) {
                cookie.value = JSON.parse(cookieValue.substring(key.length, cookieValue.length));
            }
        }
        return cookie;
    };

    public setItem(value: ICookie, expiration: number) {
        const d: Date = new Date();
        d.setTime(d.getTime() + (expiration * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = value.key + "=" + JSON.stringify(value.value) + ";" + expires + ";path=/";
    };

    public removeItem(prop: string) {
        const d: Date = new Date();
        d.setTime(d.getTime() + (-1 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = prop + "=;" + expires + ";path=/";
        //window.sessionStorage.removeItem(prop);
    };


}
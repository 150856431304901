import IOrganization from '../models/IOrganization';
import ITeam from '../models/ITeam';
import IOrganizationTeam from '../models/IOrganizationTeam';

import IOrganizationService from '../services/IOrganizationService';
import DataRepository from './dataRepository';

import organizationData from '../data/organizations.json';
import teamData from '../data/teams.json';

export default class OrganizationRepository implements IOrganizationService {

    public async getAll(): Promise<IOrganization[]> {
        const items: IOrganization[] = [];
        organizationData.forEach(i => {
            const item: IOrganization = {
                id: i["id"],
                name: i["name"],
                location: i["location"],
                siteUrl: i["siteUrl"],
                badgeUrl: i["badgeUrl"]
            };
            items.push(item);
        });
        return items.sort((a, b) => {
            const nameA: string = a.name.toUpperCase();
            const nameB: string = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };

    public async getByName(name: string): Promise<IOrganization[]> {
        const items: IOrganization[] = [];
        const path: string = name + "/";
        organizationData.forEach(i => {
        //    if (path.toUpperCase() === i["path"].substring(0, path.length).toUpperCase()) {
        //        const item: IOrganization = {
        //            id: i["id"],
        //            name: i["name"],
        //            location: i["location"],
        //            siteUrl: i["siteUrl"],
        //            badgeUrl: i["badgeUrl"]
        //        };
        //        items.push(item);
        //    }
        });
        console.log('returning [' + items.length + '] organizations');
        return items.sort((a, b) => {
            const nameA: string = a.location.toUpperCase();
            const nameB: string = b.location.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };

    /**
     * Performs the specified action for each element in an array.
     * @param id 
     */
    public async getById(id: string): Promise<IOrganization> {
        return DataRepository.getOrganizationById(id);
    };

    public async getByPath(path: string): Promise<IOrganization> {
        const item: IOrganization = {
            id: "",
            name: "",
            location: "",
            siteUrl: "",
            badgeUrl: ""
        };
        organizationData.forEach((i, index) => {
        //    console.log("[" + i["path"] + "] === [" + path + "]");
        //    if (path.toUpperCase() === i["path"].toUpperCase()) {
        //        item.id = i["id"];
        //        item.name = i["name"];
        //        item.location = i["location"];
        //        item.siteUrl = i["siteUrl"];
        //        item.badgeUrl = i["badgeUrl"];
        //        //index = organizationData.length;
        //    }
        });
        return item;
    };

    public async getTeamById(id: string): Promise<IOrganizationTeam> {
        const item: IOrganizationTeam = {
            id: id,
            name: "",
            location: "",
            siteUrl: "",
            badgeUrl: "",
            teams: []
        };

        organizationData.forEach(i => {
            if (item.id.toUpperCase() === i["id"].toUpperCase()) {
                item.name = i["name"];
                item.location = i["location"];
                item.siteUrl = i["siteUrl"];
                item.badgeUrl = i["badgeUrl"];
                teamData.forEach(j => {
                    if (item.id.toUpperCase() === j["organizationId"].toUpperCase()) {
                        const team: ITeam = {
                            id: j["id"],
                            previousId: j["previousId"],
                            organizationId: j["organizationId"],
                            name: j["name"],
                            code: j["code"],
                            siteUrl: j["siteUrl"],
                            rankingUrl: j["rankingUrl"],
                        };
                        item.teams.push(team);
                    }
                });
            }
        });
        return item;
    };

    public async getTeamsById(id: string): Promise<ITeam[]> {
        const items: ITeam[] = [];
        teamData.forEach(i => {
            if (id.toUpperCase() === i["organizationId"].toUpperCase()) {
                const item: ITeam = {
                    id: i["id"],
                    previousId: i["previousId"],
                    organizationId: i["organizationId"],
                    name: i["name"],
                    code: i["code"],
                    siteUrl: i["siteUrl"],
                    rankingUrl: i["rankingUrl"]
                };
                items.push(item);
            }
        });
        return items;
    };

    public async getTeamByCode(id: string, code: string): Promise<ITeam> {
        const item: ITeam = {
            id: "",
            previousId: "",
            organizationId: "",
            name: "",
            code: "",
            siteUrl: "",
            rankingUrl: ""
        };
        teamData.forEach(i => {
            if (id.toUpperCase() === i["organizationId"].toUpperCase() && code.toUpperCase() === i["code"].toUpperCase()) {
                item.id = i["id"];
                item.previousId = i["previousId"];
                item.organizationId = i["organizationId"];
                item.name = i["name"];
                item.code = i["code"];
                item.siteUrl = i["siteUrl"];
                item.rankingUrl = i["rankingUrl"];
            }
        });
        return item;
    };

    public async getTeamsByName(name: string): Promise<IOrganizationTeam[]> {
        const items: IOrganizationTeam[] = [];
        const path: string = name + "/";
        organizationData.forEach(i => {
            //if (path.toUpperCase() === i["path"].substring(0, path.length).toUpperCase()) {
            //    const item: IOrganization = {
            //        id: i["id"],
            //        name: i["name"],
            //        location: i["location"],
            //        siteUrl: i["siteUrl"],
            //        badgeUrl: i["badgeUrl"]
            //    };
            //    const orgItem: IOrganizationTeam = {
            //        id: item.id,
            //        name: item.name,
            //        location: item.location,
            //        siteUrl: item.siteUrl,
            //        badgeUrl: item.badgeUrl,
            //        teams: []
            //    };
            //    teamData.forEach(j => {
            //        if (orgItem.id.toUpperCase() === j["organizationId"].toUpperCase()) {
            //            const team: ITeam = {
            //                id: j["id"],
            //                previousId: j["previousId"],
            //                organizationId: j["organizationId"],
            //                name: j["name"],
            //                code: j["code"],
            //                siteUrl: j["siteUrl"],
            //                rankingUrl: j["rankingUrl"],
            //            };
            //            orgItem.teams.push(team);
            //        }
            //    });
            //    items.push(orgItem);
            //}
        });
        return items;
    };

    public async getTeamByPath(path: string, team: string): Promise<IOrganizationTeam> {
        const item: IOrganizationTeam = {
            id: "",
            name: "",
            location: "",
            siteUrl: "",
            badgeUrl: "",
            teams: []
        };
        organizationData.forEach((i, index) => {
            //console.log("[" + i["path"] + "] === [" + path + "]");
            //if (path.toUpperCase() === i["path"].toUpperCase()) {
            //    item.id = i["id"];
            //    item.name = i["name"];
            //    item.location = i["location"];
            //    item.siteUrl = i["siteUrl"];
            //    item.badgeUrl = i["badgeUrl"];
            //    teamData.forEach(j => {
            //        if (item.id.toUpperCase() === j["organizationId"].toUpperCase() && team.toUpperCase() === j["code"].toUpperCase()) {
            //            const team: ITeam = {
            //                id: j["id"],
            //                previousId: j["previousId"],
            //                organizationId: j["organizationId"],
            //                name: j["name"],
            //                code: j["code"],
            //                siteUrl: j["siteUrl"],
            //                rankingUrl: j["rankingUrl"],
            //            };
            //            item.teams.push(team);
            //        }
            //    });
            //}
        });
        return item;
    };

    public async getTeamsByPath(path: string): Promise<IOrganizationTeam> {
        const item: IOrganizationTeam = {
            id: "",
            name: "",
            location: "",
            siteUrl: "",
            badgeUrl: "",
            teams: []
        };
        organizationData.forEach((i, index) => {
            //console.log("[" + i["path"] + "] === [" + path + "]");
            //if (path.toUpperCase() === i["path"].toUpperCase()) {
            //    item.id = i["id"];
            //    item.name = i["name"];
            //    item.location = i["location"];
            //    item.siteUrl = i["siteUrl"];
            //    item.badgeUrl = i["badgeUrl"];
            //    teamData.forEach(j => {
            //        if (item.id.toUpperCase() === j["organizationId"].toUpperCase()) {
            //            const team: ITeam = {
            //                id: j["id"],
            //                previousId: j["previousId"],
            //                organizationId: j["organizationId"],
            //                name: j["name"],
            //                code: j["code"],
            //                siteUrl: j["siteUrl"],
            //                rankingUrl: j["rankingUrl"],
            //            };
            //            item.teams.push(team);
            //        }
            //    });
            //}
        });
        return item;
    };
}

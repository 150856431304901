import React from 'react';

interface IFooterProps {

}

function Footer(props: IFooterProps) {
    return (
        <div className="fixed-bottom" style={{ backgroundColor: "#FF0000", height: "28px", marginTop: "48px" }}>
            <div className="container container-sm container-md container-lg text-white">
                <div style={{ display: "flex", paddingLeft: "20px", paddingRight: "20px" }}>
                    <span style={{ alignItems: "center", display: "flex" }}>Version 1.0.0</span>
                    <span style={{ alignItems: "center", display: "flex", marginLeft: "auto" }}>Copyright 2022 Borden Consulting Solutions</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;

import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IOrganization from '../../models/IOrganization';
import IOrganizationTeam from '../../models/IOrganizationTeam';
import ITeam from '../../models/ITeam';

//import ISiteService from '../../services/ISiteService';
//import SiteProvider from '../../providers/siteProvider';

import IOrganizationService from '../../services/IOrganizationService';
import OrganizationProvider from '../../providers/organizationProvider';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import Breadcrumbs from '../../components/breadcrumbs';
import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

interface IOrganizationTeamsProps {
    id: string;
}

function OrganizationTeams(props: IOrganizationTeamsProps) {

    const organizationService: IOrganizationService = OrganizationProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const organizationId: string = urlRoutes[urlRoutes.length - 1];

    //const sessionService: ISessionService = SessionProvider.getService();
    //sessionService.setItem("organizationId", organizationId);

    const [loading, setLoading] = useState<boolean>(true);
    const [organizationTeam, setOrganizationTeam] = useState<IOrganizationTeam>();
    const [organizationName, setOrganizationName] = useState<string>("");
    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Club Teams"
    });

    const onLoad = () => {
        organizationService.getTeamById(urlRoutes[urlRoutes.length - 1])
            .then((response: IOrganizationTeam) => {
                setOrganizationTeam(response);
                setOrganizationName(response.name + " - " + response.location);
                //sessionService.setItem("organizationName", response.name + " - " + response.location);
                setFavorite(
                    favoriteService.init(window.location, response.name + " - " + response.location, favorite.type)
                );

                setLoading(false);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div id="site-page-breadcrumbs" style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={organizationName}></Breadcrumbs>
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={organizationTeam?.badgeUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{organizationName}</span>
                        </div>
                        <div className="table-responsive">
                            <table id="team-table" className="table table-sm shadow table-hover caption-top">
                                <thead>
                                    <tr>
                                        <th className="text-start">Name</th>
                                        <th>Site</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        organizationTeam?.teams.map(t => {
                                            return (
                                                <tr key={t.id}>
                                                    <td className="text-start">
                                                        <Link to={"/Organization/Team/Detail/" + t.id} style={{ textDecoration: "none" }}>
                                                            <span>{t.name}</span>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {
                                                            t.siteUrl !== "" ?
                                                                <a href={t.siteUrl} target="_blank" style={{ textDecoration: "none" }} title="">Link</a>
                                                                :
                                                                <></>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </div>
    );
}

export default OrganizationTeams;

import ISiteService from '../services/ISiteService';
import SiteRepository from '../repos/siteRepository';
//import RosterSiteService from '../sites/roster/service';

export default class SiteProvider {

    public static getDefaultService(): ISiteService {
        return new SiteRepository();
    }

    public static getService(): ISiteService {
        return new SiteRepository();
    }

}

import React from 'react';

interface IStatsProps {

}

function Stats(props: IStatsProps) {
    return (
        <div style={{ backgroundColor: "white", marginTop: "20px", padding: "20px" }}>Here is where the stats are listed</div>
    );
}

export default Stats;
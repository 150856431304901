import React from 'react';
import { Link } from 'react-router-dom';

import './nav.css';

interface INavProps {

}

function Nav(props: INavProps) {

    const onNavItemClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (e.currentTarget !== undefined) {
            const target: HTMLAnchorElement = e.currentTarget;
            if (target !== undefined) {
                const navbar: HTMLDivElement | null = target.closest(".navbar-collapse");
                if (navbar !== null) {
                    navbar.classList.remove("show");
                }
            }

        }
    }

    return (
        <div>
            
            <div style={{ height: "28px", backgroundColor: "#FF0000" }}>&nbsp;</div>
            <div style={{ backgroundColor: "#FFFFFF" }}>
                <nav id="navbar_top" className="navbar navbar-light bg-white navbar-expand-lg">
                    <div className="container container-sm container-md container-lg">
                        <Link to={"/"} className="navbar-brand" style={{ padding: "0" }}>
                            <img className="navbar-brand-icon" src="/fram_logo.png" alt="" />
                        </Link>
                        <button className="navbar-toggler btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto bg-white">
                                <li className="nav-item">
                                    <Link to={"/Organization/List"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Clubs</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={"/Competition/List"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Competitions</span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none">
                                    <Link to={"/Matches"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Matches</span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none">
                                    <Link to={"/Standings"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Standings</span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none">
                                    <Link to={"/Stats"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Stats</span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none">
                                    <Link to={"/Players"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Players</span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none">
                                    <Link to={"/Roster/FRAM/PV/B13Premier"} className="nav-link text-dark" onClick={onNavItemClick}>
                                        <span>Roster</span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown d-none" id="myDropdown">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Treeview menu</a>
                                    <ul className="dropdown-menu">
                                        <li> <a className="dropdown-item" href="#">Dropdown item 1</a></li>
                                        <li> <a className="dropdown-item" href="#">Dropdown item 2</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Submenu item 1</a></li>
                                                <li><a className="dropdown-item" href="#">Submenu item 2</a></li>
                                                <li><a className="dropdown-item" href="#">Submenu item 3</a>
                                                    <ul className="submenu dropdown-menu">
                                                        <li><a className="dropdown-item" href="#">Multi level 1</a></li>
                                                        <li><a className="dropdown-item" href="#">Multi level 2</a></li>
                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" href="#">Submenu item 4</a></li>
                                                <li><a className="dropdown-item" href="#">Submenu item 5</a></li>
                                            </ul>
                                        </li>
                                        <li><a className="dropdown-item" href="#">Dropdown item 3</a></li>
                                        <li><a className="dropdown-item" href="#">Dropdown item 4</a></li>
                                    </ul>
                                </li>
                            </ul>
                            <span className="navbar-text"></span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Nav;

import IFavoriteService from '../services/IFavoriteService';
import FavoriteRepository from '../repos/favoriteRepository';

export default class FavoriteProvider {

    public static getService(): IFavoriteService {
        const repo: FavoriteRepository = new FavoriteRepository();
        return repo;
    }

}
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IFavorite from '../models/IFavorite';

import Favorite from '../components/favorite';
import IFavoriteService from '../services/IFavoriteService';
import FavoriteProvider from '../providers/favoriteProvider';

interface IHomeProps {

}

function Home(props: IHomeProps) {

    const [loading, setLoading] = useState<boolean>(true);
    const [favorites, setFavorites] = useState<IFavorite[]>([]);

    const favoriteService: IFavoriteService = FavoriteProvider.getService();
    //service.exists(favorite.name)
    //    .then((response: boolean) => {
    //        console.log('favorite exists: ' + response);
    //    });

    const onLoad = () => {

        //const favorite: IFavorite = {
        //    name: "FRAM SC FRAM B13 Premier",
        //    url: "/Organization/Team/Detail/7ca6256d-f565-ed11-ad9b-28d0eaec2330",
        //    type: "Team"
        //}
        //service.add(favorite);

        favoriteService.getAll()
            .then((response: IFavorite[]) => {
                setFavorites(response);
                setLoading(false);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);


    return (
        <div>
            <div>
                <img src="Screen_Shot_2021-03-20_at_1.40.55_PM.png" style={{ height: "auto", maxWidth: "100%" }} />
            </div>
            <div className="site-page">
                <div className="table-responsive">
                    <table className="table table-sm shadow table-hover caption-top">
                        <caption>Favorites</caption>
                        <thead>
                            <tr>
                                <th style={{ width: "40px" }}>&nbsp;</th>
                                <th className="text-start">Name</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="progress" style={{ height: "32px" }}>
                                                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: "100%" }}>Loading ...</div>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    favorites.length === 0 ?
                                        <tr>
                                            <td style={{ textAlign: "center" }} colSpan={3}>You Currently Have No Favorites</td>
                                        </tr>
                                        :
                                        favorites.map((f, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <Favorite service={favoriteService} favorite={f}></Favorite>
                                                    </td>
                                                    <td className="text-start">
                                                        <Link to={f.url}>{f.name}</Link>
                                                    </td>
                                                    <td>{f.type}</td>
                                                </tr>
                                            )
                                        })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Home;

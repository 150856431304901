import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ICompetitionTeamResult from '../../models/ICompetitionTeamResult';
import IOrganization from '../../models/IOrganization';
import IOrganizationTeam from '../../models/IOrganizationTeam';
import ITeam from '../../models/ITeam';
import IFlightHistory from '../../models/IFlightHistory';

import IOrganizationService from '../../services/IOrganizationService';
import OrganizationProvider from '../../providers/organizationProvider';

import ITeamService from '../../services/ITeamService';
import TeamProvider from '../../providers/teamProvider';

import ISessionService from '../../services/ISessionService';
import SessionProvider from '../../providers/sessionProvider';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import Breadcrumbs from '../../components/breadcrumbs';
import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

interface IOrganizationTeamProps {
    id: string;
}

function OrganizationTeam(props: IOrganizationTeamProps) {

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const teamId: string = urlRoutes[urlRoutes.length - 1];

    const organizationService: IOrganizationService = OrganizationProvider.getService();
    const teamService: ITeamService = TeamProvider.getService();
    const favoriteService: IFavoriteService = FavoriteProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();

    //const sessionService: ISessionService = SessionProvider.getService();
    //sessionService.setItem("teamId", teamId);
    //const sessionOrganizationId: string = sessionService.getItem("organizationId");
    //const sessionOrganizationName: string = sessionService.getItem("organizationName");

    //const siteService: ISiteService = SiteProvider.getService(props.type);

    const [loading, setLoading] = useState<boolean>(true);
    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Club Team"
    });
    //const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    //const [organizationTeams, setOrganizationTeams] = useState<IOrganizationTeam[]>([]);
    const [organization, setOrganization] = useState<IOrganization>();
    //const [organizationId, setOrganizationId] = useState<string>(sessionOrganizationId);
    //const [organizationName, setOrganizationName] = useState<string>(sessionOrganizationName);
    const [teamName, setTeamName] = useState<string>("");
    const [team, setTeams] = useState<ITeam>();
    const [competitionResults, setCompetitionResults] = useState<ICompetitionTeamResult[]>([]);
    const [flightHistory, setFlightHistory] = useState<IFlightHistory[]>([]);

    const onLoad = () => {
        
        teamService.getById(teamId)
            .then((response: ITeam) => {
                const organizationId: string = response.organizationId;
                setTeams(response);
                setTeamName(response.name);
                const responseName: string = response.name;

                teamService.getCompetitionResultsById(teamId)
                    .then((response: ICompetitionTeamResult[]) => {
                        setCompetitionResults(response);

                        organizationService.getById(organizationId)
                            .then((response: IOrganization) => {
                                setOrganization(response);

                                setFavorite(
                                    favoriteService.init(window.location, response.name + " - " + response.location + " | " + responseName, favorite.type)
                                );

                                setLoading(false);
                            });
                    });

                teamService.getFlightHistoryById(teamId)
                    .then((response: IFlightHistory[]) => {
                        setFlightHistory(response);
                    });
            });

    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div>Loading ...</div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div id="site-page-breadcrumbs" style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={teamName}></Breadcrumbs>
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={organization?.badgeUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{teamName}</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-sm shadow table-hover caption-top">
                                <caption>Competition History</caption>
                                <thead>
                                    <tr>
                                        <th style={{ width: "40px" }}>&nbsp;</th>
                                        <th className="text-start">Name</th>
                                        <th>Year</th>
                                        <th>Division</th>
                                        <th>Result</th>
                                        <th>Site</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        competitionResults.length === 0 ?
                                            <tr><td className="text-center" colSpan={6}>No Records Found</td></tr>
                                            :
                                            competitionResults.map((c, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: "40px" }}>
                                                            <img src={c.competition.logoUrl} width="24px" height="24px" />
                                                        </td>
                                                        <td className="text-start">
                                                            <Link to={"/Competition/Division/Detail/" + c.division.id}>{c.competition.name}</Link>
                                                        </td>
                                                        <td>{c.competition.year.toString()}</td>
                                                        <td>{c.division.name}</td>
                                                        <td>{c.result}</td>
                                                        <td>
                                                            {
                                                                c.competition.siteUrl !== "" ?
                                                                    <a href={c.competition.siteUrl} style={{ textDecoration: "none" }} target="_blank">Link</a>
                                                                    :
                                                                    <></>
                                                            }
                                                            
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-sm shadow table-hover caption-top">
                                <caption>Flight History</caption>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        flightHistory.length === 0 ?
                                            <tr><td className="text-center" colSpan={2}>No Records Found</td></tr>
                                            :
                                            flightHistory.map((f, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{f.flight}</td>
                                                        <td>{f.year}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

            }
        </div>
    );
}

export default OrganizationTeam;

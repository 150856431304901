import React, { useRef, useState, useEffect } from 'react';
//import IOrganization from '../models/IOrganization';
//import ITeam from '../models/ITeam';
//import IOrganizationService from '../services/IOrganizationService';
//import OrganizationProvider from '../providers/organizationProvider';
//import IPlayer from '../models/IPlayer';
import IPlayerService from '../../services/IPlayerService';
import PlayerProvider from '../../providers/playerProvider';
import ITeamRoster from '../../models/ITeamRoster';

interface IRosterProps {
    teamId: string;
    teamName: string;
}

function Roster(props: IRosterProps) {

    //const organizationService: IOrganizationService = OrganizationProvider.getService();
    const playerService: IPlayerService = PlayerProvider.getService();

    const [roster, setRoster] = useState<ITeamRoster[]>([]);
    const [teamId, setTeamId] = useState<string>(props.teamId);
    const [teamName, setTeamName] = useState<string>(props.teamName);

    const onLoad = () => {
        console.log('calling Roster onLoad: ' + props.teamId);
        setRoster([]);
        playerService.getTeamRosterById(props.teamId)
            .then((response: ITeamRoster[]) => {
                setRoster(response);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div>
            <div className="border-bottom mb-3">{teamName}</div>
            <div className="row">
                {
                    roster.map(p => {
                        const pictureUrl: string = p.pictureUrl === "" ? "/profiles/unknown_user_profile_male.png" : p.pictureUrl;
                        return (
                            <div key={p.id} className="col">
                                <div className="card mb-3" style={{ width: "96px" }}>
                                    <img src={pictureUrl} className="card-img-top" height="96px" width="96px" alt="..." />
                                    <div className="card-body" style={{ padding: "4px", height: "96px" }}>
                                        <div className="card-text text-center" style={{ fontSize: "12px" }}>
                                            <div>{p.lastName}, {p.firstName}</div>
                                            <div>{p.position} #{p.number}</div>
                                            <div className="d-none">{p.dob === null ? "unknown" : p.dob.toLocaleDateString()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Roster;
import React from 'react';

interface IMatchProps {
    id: string;
}

function Match(props: IMatchProps) {

    //console.log('did this get hit?');

    return (
        <div className="site-page">
            <div className="d-flex justify-content-around">
                <div>
                    <div className="text-center mb-3">SoCal Fall League</div>
                </div>
                <div>
                    <div className="text-center mb-3">&nbsp;</div>
                </div>
                <div>
                    <div className="text-center mb-3">Full Time</div>
                </div>
            </div>
            <div className="d-flex justify-content-around">
                <div>
                    <div className="text-center">
                        <img src="teamBadges/fram.png" width="48px" height="48px" />
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-around" style={{ minWidth: "200px", maxWidth: "600px", fontSize: "36px" }}>
                        <div>1</div>
                        <div>-</div>
                        <div>2</div>
                    </div>
                </div>
                <div>
                    <div className="text-center">
                        <img src="teamBadges/StrikersFC.png" width="48px" height="48px" />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-around">
                <div>
                    <div className="text-center">FRAM SC FRAM B13 Premier</div>
                </div>
                <div>
                </div>
                <div>
                    <div className="text-center">Strikers FC - CM/NB Strikers FC - CM B2013 Duarte</div>
                </div>
            </div>
        </div>
    );
}

export default Match;
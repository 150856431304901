import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './home';
import Organization from '../sites/organization/site';
import OrganizationTeams from '../sites/organization/teams';
import OrganizationTeam from '../sites/organization/team';
import Teams from './teams';
import Competition from '../sites/competition/site';
import CompetitionDivisions from '../sites/competition/divisions';
import CompetitionDivision from '../sites/competition/division';
import CompetitionBracket from '../sites/competition/bracket';
import CompetitionMatch from '../sites/competition/match';
import CompetitionTeam from '../sites/competition/team';
import Match from './match';
import Matches from './matches';
import Standings from './standings';
import Stats from './stats';
import Players from './players';
import RosterSite from '../sites/roster/site';

import ISiteService from '../services/ISiteService';
import SiteProvider from '../providers/siteProvider';
import IRoutes from '../models/IRoutes';

interface IRoutingProps {

}

function Routing(props: IRoutingProps) {

    const service: ISiteService = SiteProvider.getDefaultService();
    const routes: IRoutes = service.getRoutes(window.location);
    //console.log(routes);

    return (
        <Routes>
            <Route path={"/Organization/Team/Detail/*"} element={<OrganizationTeam id={routes.id} />} />
            <Route path={"/Organization/Team/List/*"} element={<OrganizationTeams id={routes.id} />} />
            <Route path={"/Organization/List/*"} element={<Organization id={routes.id} />} />
            <Route path={"/Teams"} element={<Teams />} />
            <Route path={"/Competition/List/*"} element={<Competition />} />
            <Route path={"/Competition/Division/List/*"} element={<CompetitionDivisions />} />
            <Route path={"/Competition/Division/Detail/*"} element={<CompetitionDivision />} />
            <Route path={"/Competition/Bracket/Detail/*"} element={<CompetitionBracket />} />
            <Route path={"/Competition/Match/Detail/*"} element={<CompetitionMatch />} />
            <Route path={"/Competition/Team/Detail/*"} element={<CompetitionTeam />} />
            <Route path={"/Match/*"} element={<Match id="" />} />
            <Route path={"/Matches/*"} element={<Matches />} />
            <Route path={"/Standings"} element={<Standings />} />
            <Route path={"/Stats"} element={<Stats />} />
            <Route path={"/Players"} element={<Players />} />
            <Route path={"/Roster/*"} element={<RosterSite type={routes.site} />} />
            <Route path={"*"} element={<Home />} />
        </Routes>
    );
}

export default Routing;

import React, { useState, useRef, useEffect } from 'react';
import ISeason from '../shared/ISeason';
import IGenderDivision from '../shared/IGenderDivision';
//import IDivision from '../shared/IDivision';
import IFlight from '../shared/IFlight';
import { GenderType } from '../shared/GenderType';

import ICompetition from '../models/ICompetition';
import IDivision from '../models/IDivision';
import ICompetitionService from '../services/ICompetitionService';
import CompetitionProvider from '../providers/competitionProvider';

import './standings.css';

interface IStandingsProps {

}

function Standings(props: IStandingsProps) {

    const competitionService: ICompetitionService = CompetitionProvider.getService();
    const [loading, setLoading] = useState<boolean>(true);


    const seasonSelectRef = useRef<HTMLSelectElement>(null);
    const competitionSelectRef = useRef<HTMLSelectElement>(null);
    const genderSelectRef = useRef<HTMLSelectElement>(null);
    const ageSelectRef = useRef<HTMLSelectElement>(null);
    const divisionSelectRef = useRef<HTMLSelectElement>(null);

    const [seasons, setSeasons] = useState<number[]>([]);
    const [competitions, setCompetitions] = useState<ICompetition[]>([]);

    const [divisions, setDivisions] = useState<IDivision[]>([]);
    const [genders, setGenders] = useState<string[]>([]);
    const [ages, setAges] = useState<string[]>([]);
    const [flights, setFlights] = useState<IDivision[]>([]);

    const onLoad = () => {
        competitionService.getYears()
            .then((response: number[]) => {
                setSeasons(response);
            });
    }

    useEffect(() => {
        onLoad();
    }, []);


    const onSeasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const year: number = Number(e.currentTarget.value);
        //console.log(year);
        competitionService.getByYear(year)
            .then((response: ICompetition[]) => {
                setCompetitions(response);
                if (competitionSelectRef.current) {
                    competitionSelectRef.current.value = "";
                }
                setGenders([]);
                if (genderSelectRef.current) {
                    genderSelectRef.current.value = "";
                }
                setAges([]);
                if (ageSelectRef.current) {
                    ageSelectRef.current.value = "";
                }
                setFlights([]);
                if (divisionSelectRef.current) {
                    divisionSelectRef.current.value = "";
                }
                //console.log(response);
            });
    }

    const onCompetitionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const id: string = e.currentTarget.value;
        competitionService.getDivisionsById(id)
            .then((response: IDivision[]) => {
                setDivisions(response);
                const uniqueGenders: string[] = [];
                response.forEach(i => {
                    if (!uniqueGenders.includes(i.gender)) {
                        uniqueGenders.push(i.gender);
                    }
                });
                setGenders(uniqueGenders.sort((a, b) => {
                    const genderA: string = a.toUpperCase();
                    const genderB: string = b.toUpperCase();
                    if (genderA < genderB) {
                        return -1;
                    }
                    if (genderA > genderB) {
                        return 1;
                    }
                    return 0;
                }));
                if (genderSelectRef.current) {
                    genderSelectRef.current.value = "";
                }
                setAges([]);
                if (ageSelectRef.current) {
                    ageSelectRef.current.value = "";
                }
                setFlights([]);
                if (divisionSelectRef.current) {
                    divisionSelectRef.current.value = "";
                }
            });
    }

    const onGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const gender: string = e.currentTarget.value;
        const uniqueAges: string[] = [];
        divisions.forEach(i => {
            if (i.gender === gender && !uniqueAges.includes(i.age)) {
                uniqueAges.push(i.age);
            }
        });
        setAges(uniqueAges.sort((a, b) => {
            const genderA: string = a.toUpperCase();
            const genderB: string = b.toUpperCase();
            if (genderA < genderB) {
                return -1;
            }
            if (genderA > genderB) {
                return 1;
            }
            return 0;
        }));
        setAges(uniqueAges);
        if (ageSelectRef.current) {
            ageSelectRef.current.value = "";
        }
        setFlights([]);
        if (divisionSelectRef.current) {
            divisionSelectRef.current.value = "";
        }
    }

    const onAgeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const options: IDivision[] = [];
        let gender: string = "";
        if (genderSelectRef.current) {
            gender = genderSelectRef.current.value;
        }
        let age: string = "";
        if (ageSelectRef.current) {
            age = ageSelectRef.current.value;
        }
        divisions.forEach(i => {
            if (i.gender === gender && i.age === age) {
                options.push(i);
            }
        });
        setFlights(options);
    }

    const onDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    }

    return (
        <div className="site-page">
            <div className="row mb-3">
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Season</label>
                        <select ref={seasonSelectRef} className="form-select" onChange={onSeasonChange}>
                            <option value="" disabled selected={true} >... Select A Season</option>
                            {
                                seasons.map(s => {
                                    return <option value={s}>{s}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Competition</label>
                        <select ref={competitionSelectRef} className="form-select" onChange={onCompetitionChange}>
                            <option value="" disabled selected={true}>... Select A Competition</option>
                            {
                                competitions.map(c => {
                                    return <option value={c.id}>{c.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Gender</label>
                        <select ref={genderSelectRef} className="form-select" onChange={onGenderChange}>
                            <option value="" disabled selected={true}>... Select A Gender</option>
                            {
                                genders.map(g => {
                                    return <option value={g}>{g}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Age</label>
                        <select ref={ageSelectRef} className="form-select" onChange={onAgeChange}>
                            <option value="" disabled selected={true}>... Select An Age</option>
                            {
                                ages.map(a => {
                                    return <option value={a}>{a}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group input-group-sm">
                        <label className="input-group-text">Division</label>
                        <select ref={divisionSelectRef} className="form-select" onChange={onDivisionChange} >
                            <option value="" disabled selected={true}>... Select A Division</option>
                            {
                                flights.map(f => {
                                    return <option value={f.id}>{f.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <table className="table table-hover table-sm">
                <thead>
                    <tr>
                        <th>Team</th>
                        <th>GP</th>
                        <th>W</th>
                        <th>L</th>
                        <th>D</th>
                        <th>GF</th>
                        <th>GA</th>
                        <th>GD</th>
                        <th>Pts</th>
                        <th>Last 5</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Beach Futbol Club Beach FC SB B2013 B. Lopez</td>
                        <td>2</td>
                        <td>0</td>
                        <td>1</td>
                        <td>1</td>
                        <td>5</td>
                        <td>7</td>
                        <td>-2</td>
                        <td>1</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Beach Futbol Club Beach FC ES B2013 J. Villa</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td>4</td>
                        <td>-2</td>
                        <td>0</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Beach Futbol Club Beach FC LB B2013 B. Artiaga</td>
                        <td>2</td>
                        <td>0</td>
                        <td>2</td>
                        <td>0</td>
                        <td>0</td>
                        <td>3</td>
                        <td>-3</td>
                        <td>0</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CDA Slammers HB B13 Vargas</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td>4</td>
                        <td>-3</td>
                        <td>0</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>FRAM SC FRAM B13 Premier</td>
                        <td>12</td>
                        <td>7</td>
                        <td>3</td>
                        <td>2</td>
                        <td>34</td>
                        <td>28</td>
                        <td>+6</td>
                        <td>23</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Pateadores Soccer Club HB B2013</td>
                        <td>2</td>
                        <td>2</td>
                        <td>0</td>
                        <td>0</td>
                        <td>5</td>
                        <td>3</td>
                        <td>+2</td>
                        <td>6</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Sand and Surf SC B13 Premier</td>
                        <td>2</td>
                        <td>0</td>
                        <td>1</td>
                        <td>1</td>
                        <td>7</td>
                        <td>8</td>
                        <td>-1</td>
                        <td>1</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Strikers FC - CM/NB Strikers FC - CM B2013 Duarte</td>
                        <td>2</td>
                        <td>1</td>
                        <td>1</td>
                        <td>0</td>
                        <td>6</td>
                        <td>5</td>
                        <td>+1</td>
                        <td>3</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Standings;
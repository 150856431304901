import IFavoriteService from '../services/IFavoriteService';
import IFavorite from '../models/IFavorite';
import ICookie from '../models/ICookie';

import ICookieService from '../services/ICookieService';
import CookieProvider from '../providers/cookieProvider';

export default class FavoriteRepository implements IFavoriteService {

    private service: ICookieService = CookieProvider.getService();
    private cookieKey: string = "favorites";
    private cookieExpiration: number = 730;

    public init(location: Location, name: string, type: string): IFavorite {
        const url = new URL(location.href);

        return {
            name: name,
            url: url.pathname,
            type: type
        }
    };

    public async add(item: IFavorite) {

        const source: ICookie = this.service.getItem(this.cookieKey);
        if (source.value === null) {
            source.value = [];
        }
        let favorites: IFavorite[] = source.value;
        let exists: boolean = false;
        favorites.forEach(f => {
            if (item.name.toUpperCase() === f.name.toUpperCase()) {
                f.name = item.name;
                f.url = item.url;
                f.type = item.type;
                exists = true;
            }
        });
        if (!exists) {
            favorites.push(item);
        }
        source.value = favorites.sort((a, b) => {
            const nameA: string = a.name.toUpperCase();
            const nameB: string = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        this.service.setItem(source, this.cookieExpiration);

    };

    public async remove(name: string) {

        const source: ICookie = this.service.getItem(this.cookieKey);
        if (source.value === null) {
            source.value = [];
        }
        let favorites: IFavorite[] = source.value;
        //console.log('there are ' + favorites.length + ' favorite(s)');
        source.value = favorites.filter(f => f.name.toUpperCase() !== name.toUpperCase());
        //console.log('you know have: ' + source.value.length + ' favorite(s)');
        this.service.setItem(source, this.cookieExpiration);

    };

    public async getByName(name: string): Promise<IFavorite> {
        let item: IFavorite = {
            name: name,
            url: "",
            type: ""
        }

        const source: ICookie = this.service.getItem(this.cookieKey);
        if (source.value === null) {
            source.value = [];
        }
        let favorites: IFavorite[] = source.value;
        favorites.forEach((f, i) => {
            if (name.toUpperCase() === f.name.toUpperCase()) {
                item = favorites[i];
            }
        });

        return item;
    };

    public async getAll(): Promise<IFavorite[]> {
        const source: ICookie = this.service.getItem(this.cookieKey);
        if (source.value === null) {
            source.value = [];
        }

        return source.value;
    };

    public async exists(name: string): Promise<boolean> {

        const source: ICookie = this.service.getItem(this.cookieKey);
        if (source.value === null) {
            source.value = [];
        }
        let favorites: IFavorite[] = source.value;
        let exists: boolean = false;
        favorites.forEach((f, i) => {
            if (name.toUpperCase() === f.name.toUpperCase()) {
                exists = true;
            }
        });

        return exists;
    };

}
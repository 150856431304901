import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Matches from './matches';
import ICompetitionDivisionResult from '../../models/ICompetitionDivisionResult';
import ICompetitionBracketResult from '../../models/ICompetitionBracketResult';
import './bracket.css';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import IDivisionService from '../../services/IDivisionService';
import DivisionProvider from '../../providers/divisionProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

import Breadcrumbs from '../../components/breadcrumbs';
import IMatchResult from '../../models/IMatchResult';

interface IBracketProps {
    
}

function Bracket(props: IBracketProps) {

    const divisionService: IDivisionService = DivisionProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const favoriteService: IFavoriteService = FavoriteProvider.getService();

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const bracketId: string = urlRoutes[urlRoutes.length - 1];

    const [competitionDivision, setCompetitionDivision] = useState<ICompetitionDivisionResult>();

    const [loading, setLoading] = useState<boolean>(true);
    const [bracket, setBracket] = useState<ICompetitionBracketResult>();
    const [bracketName, setBracketName] = useState<string>("");
    const [matchResults, setMatchResults] = useState<IMatchResult[]>([]);

    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Competition Bracket"
    });

    const onLoad = () => {

        divisionService.getResultByBracketId(bracketId)
            .then((response: ICompetitionDivisionResult) => {

                setCompetitionDivision(response);
                //setDivision(response.division);
                //setDivisionName(response.division.name);
                if (response.brackets.length > 0) {
                    setBracketName(response.brackets[0].name);
                    setBracket(response.brackets[0]);
                    setMatchResults(response.brackets[0].matches);

                    setFavorite(
                        favoriteService.init(window.location, response.competition.name + " - " + response.competition.year + " | " + response.division.name + " | " + response.brackets[0].name, favorite.type)
                    );
                }


                setLoading(false);
                console.log(response);
            });
    }

    const showAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        const target: HTMLElement = e.currentTarget;
        if (target !== undefined) {
            const parent = target.closest(".team-match-container");
            if (parent !== null) {
                parent.querySelectorAll(".match-row .match-list-collapse .match-list-toggle").forEach(i => {
                    const item: HTMLElement = i as HTMLElement;
                    item.click();
                });
            }
        }
    }

    const closeAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        const target: HTMLElement = e.currentTarget;
        if (target !== undefined) {
            const parent = target.closest(".team-match-container");
            if (parent !== null) {
                parent.querySelectorAll(".match-row .col:not(.match-list-collapse) .match-list-toggle").forEach(i => {
                    const item: HTMLElement = i as HTMLElement;
                    item.click();
                });
            }
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={bracketName} />
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={competitionDivision?.competition.logoUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{bracketName}</span>
                        </div>
                        <div className={bracket?.showStandings ? "table-responsive" : "d-none"}>
                            <table id="competition-table" className="table shadow table-sm table-hover caption-top">
                                <caption>Standings</caption>
                                <thead>
                                    <tr>
                                        <th style={{ width: "40px" }}></th>
                                        <th className="text-start">Name</th>
                                        <th title="Matches Played" style={{ width: "34px" }}>MP</th>
                                        <th title="Wins" style={{ width: "34px" }}>W</th>
                                        <th title="Draws" style={{ width: "34px" }}>D</th>
                                        <th title="Loses" style={{ width: "34px" }}>L</th>
                                        <th title="Goals For" style={{ width: "34px" }}>GF</th>
                                        <th title="Goals Against" style={{ width: "34px" }}>GA</th>
                                        <th title="Goal Differential" style={{ width: "34px" }}>GD</th>
                                        <th title="Points" style={{ width: "34px" }}>PTS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bracket?.teams.length === 0 ?
                                            <tr><td className="text-center" colSpan={10}>No Teams Found</td></tr>
                                            :
                                            bracket?.teams.map((t, i) => {
                                                return (
                                                    <tr key={t.id} className="hidable">
                                                        <td style={{ width: "40px" }}>
                                                            <img src={t.logoUrl} width="24px" height="24px" />
                                                        </td>
                                                        <td className="text-start">
                                                            <Link to={"/Competition/Team/Detail/" + t.bracketTeamId}>{t.name}</Link>
                                                        </td>
                                                        <td>{t.gamesPlayed}</td>
                                                        <td>{t.wins}</td>
                                                        <td>{t.draws}</td>
                                                        <td>{t.loses}</td>
                                                        <td>{t.goalsFor}</td>
                                                        <td>{t.goalsAgainst}</td>
                                                        <td>{t.goalDifferencial}</td>
                                                        <td>{t.points}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="team-match-container">
                            <div style={{ color: "#6c757d", marginBottom: "8px" }}>
                                <span>Matches</span>
                                <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={closeAllMatches}>Close All</span>
                                <span style={{ marginLeft: "8px", float: "right" }}>|</span>
                                <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={showAllMatches}>Open All</span>
                            </div>
                            <Matches matchResults={matchResults}></Matches>
                        </div>
                    </div>
            }
        </div>
    )

}

export default Bracket

import IBracket from '../models/IBracket';
import ICompetitionTeamResult from '../models/ICompetitionTeamResult';
import ITeam from '../models/ITeam';
import ICompetition from '../models/ICompetition';
import IDivision from '../models/IDivision';
import IFlightHistory from '../models/IFlightHistory';

import ITeamService from '../services/ITeamService';

import DataRepository from './dataRepository';

import teamData from '../data/teams.json';
import competitionData from '../data/competitions.json';
import divisionData from '../data/divisions.json';
import bracketData from '../data/brackets.json';
import bracketTeamData from '../data/bracketTeams.json';
import competitionTeamResultData from '../data/competitionTeamResults.json';
import flightHistoryData from '../data/teamFlightHistories.json';

//import teamRosterData from '../data/teamRoster.json';
//import playerData from '../data/players.json';

export default class TeamRepository implements ITeamService {

    public async getById(id: string): Promise<ITeam> {
        return DataRepository.getTeamById(id);
    };

    public async getCompetitionResultsById(id: string): Promise<ICompetitionTeamResult[]> {
        const items: ICompetitionTeamResult[] = [];

        competitionTeamResultData.forEach(i => {
            if (id.toUpperCase() === i["teamId"].toUpperCase()) {
                const competitionId: string = i["competitionId"];
                const divisionId: string = i["divisionId"];
                const bracketId: string = i["bracketId"];
                
                const item: ICompetitionTeamResult = {
                    competition: DataRepository.getCompetitionById(competitionId),
                    division: DataRepository.getDivisionById(divisionId),
                    bracket: DataRepository.getBracketById(bracketId),
                    team: DataRepository.getTeamById(id),
                    result: i["result"]
                };

                items.push(item);
            }
        });

        return items.sort((a, b) => {
            const startA: Date = a.competition.startDate === null ? new Date : a.competition.startDate;
            const startB: Date = b.competition.startDate === null ? new Date : b.competition.startDate;
            if (startA < startB) {
                return -1;
            }
            if (startA > startB) {
                return 1;
            }
            return 0;
        });
    };

    public async getFlightHistoryById(id: string): Promise<IFlightHistory[]> {
        const items: IFlightHistory[] = [];
        flightHistoryData.forEach(i => {
            if (id.toUpperCase() === i["teamId"].toUpperCase()) {
                const item: IFlightHistory = {
                    flight: i["flight"],
                    year: Number(i["year"])
                };
                items.push(item);
            }
        });
        return items.sort((a, b) => {
            const itemA: Number = a.year;
            const itemB: Number = b.year;
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }
            return 0;
        });
    };

    public async getByBracketTeamId(id: string): Promise<ITeam> {
        let teamId: string = "";
        bracketTeamData.forEach(i => {
            if (id.toUpperCase() === i["id"].toUpperCase()) {
                teamId = i["teamId"];
            }
        });
        return DataRepository.getTeamById(teamId);
    };

}

import IMatch from '../models/IMatch';
import IMatchService from '../services/IMatchService';
import ITeam from '../models/ITeam';
import IMatchResult from '../models/IMatchResult';
import IBracketStanding from '../models/IBracketStanding';
import IMatchResultDetail from '../models/IMatchResultDetail';
import IMatchStats from '../models/IMatchStats';

import DataRepository from './dataRepository';

import bracketData from '../data/brackets.json';
import bracketTeamData from '../data/bracketTeams.json';
import matchData from '../data/matches.json';
import teamData from '../data/teams.json';
import organizationData from '../data/organizations.json';

export default class MatchRepository implements IMatchService {

    public async getAll(): Promise<IMatchResult[]> {
        const items: IMatchResult[] = [];

        matchData.forEach(i => {
            const item: IMatchResult = {
                id: i["id"],
                date: new Date(i["date"]),
                label: i["label"],
                homeTeamId: i["homeTeamId"],
                homeTeamName: "",
                homeTeamBadgeUrl: "",
                homeScore: i["homeScore"] === null ? null : Number(i["homeScore"]),
                homePenalties: i["homePenalties"] === null ? null : Number(i["homePenalties"]),
                awayTeamId: i["awayTeamId"],
                awayTeamName: "",
                awayTeamBadgeUrl: "",
                awayScore: i["awayScore"] === null ? null : Number(i["awayScore"]),
                awayPenalties: i["awayPenalties"] === null ? null : Number(i["awayPenalties"]),
                location: DataRepository.getLocationById(i["locationId"]),
                field: i["field"],
                state: i["state"],
                videoUrl: i["videoUrl"]
            };
            let homeTeam: ITeam | null;
            let awayTeam: ITeam | null;
            bracketTeamData.forEach(j => {
                if (item.homeTeamId === j["id"]) {
                    homeTeam = DataRepository.getTeamById(j["teamId"]);
                    item.homeTeamName = homeTeam.name;
                }
                if (item.awayTeamId === j["id"]) {
                    awayTeam = DataRepository.getTeamById(j["teamId"]);
                    item.awayTeamName = awayTeam.name;
                }
            });
            organizationData.forEach(o => {
                if (homeTeam !== null) {
                    if (homeTeam.organizationId === o.id) {
                        item.homeTeamBadgeUrl = o["badgeUrl"];
                    }
                }
                if (awayTeam !== null) {
                    if (awayTeam.organizationId === o.id) {
                        item.awayTeamBadgeUrl = o["badgeUrl"];
                    }
                }
            });

            items.push(item);
        });

        return items;
    };

    public async getStandings(id: string): Promise<IBracketStanding[]> {
        const items: IBracketStanding[] = [];

        //matchData.forEach(i => {
        //    const item: IBracketStanding = {
        //        id: i["id"],
        //        date: new Date(i["date"]),
        //        homeTeamId: i["homeTeamId"],
        //        homeTeamName: "",
        //        homeTeamBadgeUrl: "",
        //        homeScore: i["homeScore"] === null ? null : Number(i["homeScore"]),
        //        awayTeamId: i["awayTeamId"],
        //        awayTeamName: "",
        //        awayTeamBadgeUrl: "",
        //        awayScore: i["awayScore"] === null ? null : Number(i["awayScore"]),
        //        locationId: i["locationId"],
        //        state: i["state"],
        //        videoUrl: i["videoUrl"]
        //    };
        //    let homeTeam: ITeam | null;
        //    let awayTeam: ITeam | null;
        //});

        return items;
    };

    public async getMatchResultDetailById(id: string): Promise<IMatchResultDetail> {
        let item: IMatchResultDetail = {
            id: id,
            date: new Date(),
            label: "",
            homeTeamId: "",
            homeTeamBadgeUrl: '',
            homeTeamName: '',
            homeScore: null,
            homePenalties: null,
            homeTeamHighlights: [],
            awayTeamId: "",
            awayTeamBadgeUrl: '',
            awayTeamName: '',
            awayScore: null,
            awayPenalties: null,
            awayTeamHighlights: [],
            stats: {
                homeShots: 0,
                homeShotsOnTarget: 0,
                homeFouls: 0,
                homeYellowCards: 0,
                homeRedCards: 0,
                homeCorners: 0,
                awayShots: 0,
                awayShotsOnTarget: 0,
                awayFouls: 0,
                awayYellowCards: 0,
                awayRedCards: 0,
                awayCorners: 0,
            },
            location: {
                id: "",
                name: "",
                field: "",
                address: "",
                cityStateZip: "",
                homeOrganizationId: "",
                mapUrl: ""
            },
            field: "",
            state: "",
            videoUrl: "",
        }

        return item;
    };
}

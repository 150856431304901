import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Matches from './matches';

import ICompetitionService from '../../services/ICompetitionService';
import CompetitionProvider from '../../providers/competitionProvider';

import ITeamService from '../../services/ITeamService';
import TeamProvider from '../../providers/teamProvider';

import Favorite from '../../components/favorite';
import IFavorite from '../../models/IFavorite';
import IFavoriteService from '../../services/IFavoriteService';
import FavoriteProvider from '../../providers/favoriteProvider';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import IOrganizationService from '../../services/IOrganizationService';
import OrganizationProvider from '../../providers/organizationProvider';

import Breadcrumbs from '../../components/breadcrumbs';
import IBreadcrumb from '../../models/IBreadcrumb';
import ITeam from '../../models/ITeam';
import IOrganization from '../../models/IOrganization';
import ICompetitionTeamStats from '../../models/ICompetitionTeamStats';
import IMatchResult from '../../models/IMatchResult';

import './team.css';

interface ITeamProps {

}

function Team(props: ITeamProps) {

    const favoriteService: IFavoriteService = FavoriteProvider.getService();
    const competitionService: ICompetitionService = CompetitionProvider.getService();
    const teamService: ITeamService = TeamProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const organizationService: IOrganizationService = OrganizationProvider.getService();

    const url = new URL(window.location.href);
    const urlRoutes = url.pathname.split('/');
    const bracketTeamId: string = urlRoutes[urlRoutes.length - 1];

    const [loading, setLoading] = useState<boolean>(true);
    const [team, setTeam] = useState<ITeam>();
    const [teamName, setTeamName] = useState<string>("");
    const [organizationBadgeUrl, setOrganizationBadgeUrl] = useState<string>("");

    const [teamStats, setTeamStats] = useState<ICompetitionTeamStats>();
    const [matches, setMatches] = useState<IMatchResult[]>([]);

    const matchResultsRef = useRef<HTMLDivElement>(null);

    const [favorite, setFavorite] = useState<IFavorite>({
        name: "",
        url: "",
        type: "Competition Team"
    });

    const onLoad = () => {

        competitionService.getTeamStatsByBracketTeamId(bracketTeamId)
            .then((response: ICompetitionTeamStats) => {
                setTeamStats(response);
                setMatches(response.matches);
                //console.log(response);
            });

        teamService.getByBracketTeamId(bracketTeamId)
            .then((response: ITeam) => {
                setTeam(response);
                setTeamName(response.name);

                setTimeout(function () {
                    const breadcrumbs: HTMLElement | null = document.getElementById("site-page-breadcrumbs");
                    let favoriteName: string = "";
                    if (breadcrumbs !== null) {
                        //console.log(breadcrumbs.querySelectorAll(".site-bread-crumb"));
                        breadcrumbs.querySelectorAll(".site-bread-crumb").forEach((b, i) => {
                            if (i > 0) {
                                favoriteName = (favoriteName === "" ? "" : favoriteName + " | ") + b.textContent;
                                console.log(favoriteName);
                            }
                        });
                    }
                    favoriteName = favoriteName + " | " + response.name;
                    console.log(favoriteName);
                    //alert(breadcrumbs);

                    setFavorite(
                        favoriteService.init(window.location, favoriteName, favorite.type)
                    );
                }, 250);

                organizationService.getById(response.organizationId)
                    .then((response: IOrganization) => {
                        setOrganizationBadgeUrl(response.badgeUrl);
                        setLoading(false);
                    });
            });
    };

    const showAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (matchResultsRef.current !== null) {
            matchResultsRef.current.querySelectorAll(".match-row .match-list-collapse .match-list-toggle").forEach(i => {
                const item: HTMLElement = i as HTMLElement;
                item.click();
            });
        }
    }

    const closeAllMatches = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (matchResultsRef.current !== null) {
            matchResultsRef.current.querySelectorAll(".match-row .col:not(.match-list-collapse) .match-list-toggle").forEach(i => {
                const item: HTMLElement = i as HTMLElement;
                item.click();
            });
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="site-page">
            {
                loading ?
                    <div>Loading ...</div>
                    :
                    <div>
                        <Favorite service={favoriteService} favorite={favorite}></Favorite>
                        <div id="site-page-breadcrumbs" style={{ marginTop: "8px" }}>
                            <Breadcrumbs service={siteService} currentBreadcrumb={teamName}></Breadcrumbs>
                        </div>
                        <div style={{ padding: "16px" }}>
                            <img src={teamStats?.badgeUrl} height="80px" width="80px" />
                            <span className="align-middle" style={{ marginLeft: "16px", fontSize: "28px", fontWeight: "bold" }}>{teamName}</span>
                        </div>
                        <div className="table-response">
                            <table className="table shadow table-sm table-hover caption-top">
                                <caption>Player Stats</caption>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: "40px" }}>#</th>
                                        <th className="text-start">Name</th>
                                        <th className="text-start" style={{ width: "80px" }}>Position</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Matches Played">MP</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Shots">S</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Goals">G</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Assists">A</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Yellow Card">YC</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Red Card">RC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        teamStats?.roster.players.length === 0 ?
                                            <tr><td className="text-center" colSpan={9}>No Players Found</td></tr>
                                            :
                                            teamStats?.roster.players.map((p, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center">{p.number.toString()}</td>
                                                        <td className="text-start">--, --</td>
                                                        <td className="text-start">{p.position}</td>
                                                        <td className="text-center">{p.matchesPlayed.toString()}</td>
                                                        <td className="text-center">{p.shots.toString()}</td>
                                                        <td className="text-center">{p.goals.toString()}</td>
                                                        <td className="text-center">{p.assists.toString()}</td>
                                                        <td className="text-center">{p.yellowCards.toString()}</td>
                                                        <td className="text-center">{p.redCards.toString()}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                            <table className="table shadow table-sm table-hover caption-top">
                                <caption>Goalie Stats</caption>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: "40px" }}>#</th>
                                        <th className="text-start">Name</th>
                                        <th className="text-start" style={{ width: "80px" }}>Position</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Matches Played">MP</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Shots">S</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Saves">SV</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Save Percentage">%</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Yellow Card">YC</th>
                                        <th className="text-center" style={{ width: "48px" }} title="Red Card">RC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        teamStats?.roster.goalies.length === 0 ?
                                            <tr><td className="text-center" colSpan={9}>No Players Found</td></tr>
                                            :
                                            teamStats?.roster.goalies.map((p, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center">{p.number.toString()}</td>
                                                        <td className="text-start">--, --</td>
                                                        <td className="text-start">{p.position}</td>
                                                        <td className="text-center">{p.matchesPlayed.toString()}</td>
                                                        <td className="text-center">{p.shots.toString()}</td>
                                                        <td className="text-center">{p.saves.toString()}</td>
                                                        <td className="text-center">{p.savePercentage.toString()}</td>
                                                        <td className="text-center">{p.yellowCards.toString()}</td>
                                                        <td className="text-center">{p.redCards.toString()}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div ref={matchResultsRef} className="team-match-container">
                            <div style={{ color: "#6c757d", marginBottom: "8px" }}>
                                <span>Matches</span>
                                <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={closeAllMatches}>Close All</span>
                                <span style={{ marginLeft: "8px", float: "right" }}>|</span>
                                <span style={{ marginLeft: "8px", cursor: "pointer", float: "right" }} onClick={showAllMatches}>Open All</span>
                            </div>
                            <Matches matchResults={matches}></Matches>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Team;

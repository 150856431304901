import ITeamService from '../services/ITeamService';
import TeamRepository from '../repos/teamRepository';

export default class TeamProvider {

    public static getService(): ITeamService {
        const repo: TeamRepository = new TeamRepository();
        return repo;
    }

}
import ITeamRoster from '../models/ITeamRoster';
import IPlayer from '../models/IPlayer';
import IPlayerService from '../services/IPlayerService';

import teamRosterData from '../data/teamRoster.json';
import playerData from '../data/players.json';

export default class PlayerRepository implements IPlayerService {

    public async getAll(): Promise<IPlayer[]> {
        const items: IPlayer[] = [];
        playerData.forEach(i => {
            const item: IPlayer = {
                id: i["id"],
                firstName: i["firstName"],
                lastName: i["lastName"],
                dob: null,
                pictureUrl: i["pictureUrl"]
            };
            if (i["dob"] !== null) {
                item.dob = new Date(i["dob"]);
            }
            items.push(item);
        });
        return items.sort((a, b) => {
            const nameA: string = a.lastName.toUpperCase() + "," + a.firstName.toUpperCase();
            const nameB: string = b.lastName.toUpperCase() + "," + b.firstName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };

    public async getTeamRosterById(id: string): Promise<ITeamRoster[]> {
        const items: ITeamRoster[] = [];

        teamRosterData.forEach(i => {
            if (id === i["teamId"]) {
                const item: ITeamRoster = {
                    id: i["playerId"],
                    firstName: "",
                    lastName: "",
                    dob: null,
                    pictureUrl: "",
                    teamId: i["teamId"],
                    number: i["number"],
                    position: i["position"],
                };
                playerData.forEach(j => {
                    if (item.id === j["id"]) {
                        item.firstName = j["firstName"];
                        item.lastName = j["lastName"];
                        if (j["dob"] !== null) {
                            item.dob = new Date(j["dob"]);
                        }
                        item.pictureUrl = j["pictureUrl"]
                    }
                });
                items.push(item);
            }
        });

        return items;
    };

    public async getTeamRosterByCode(code: string): Promise<ITeamRoster[]> {
        const items: ITeamRoster[] = [];


        return items;
    };

    /**
     * Performs the specified action for each element in an array.
     * @param id 
     */
    public async getById(id: string): Promise<IPlayer> {

        playerData.forEach(i => {
            if (i["id"] === id) {
                const item: IPlayer = {
                    id: i["id"],
                    firstName: i["firstName"],
                    lastName: i["lastName"],
                    dob: null,
                    pictureUrl: i["pictureUrl"]
                };
                if (i["dob"] !== null) {
                    item.dob = new Date(i["dob"]);
                }
                return item;
            }
        });
        return {
            id: "",
            firstName: "",
            lastName: "",
            dob: null,
            pictureUrl: ""
        };
    };

}
import IMatchService from '../services/IMatchService';
import MatchRepository from '../repos/matchRepository';

export default class MatchProvider {

    public static getService(): IMatchService {
        const repo: MatchRepository = new MatchRepository();
        return repo;
    }

}
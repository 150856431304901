import React, { useRef, useState, useEffect } from 'react';

import ISiteService from '../../services/ISiteService';
import SiteProvider from '../../providers/siteProvider';

import ITeamRoster from '../../models/ITeamRoster';
import IRoutes from './IRoutes';

import Roster from './players';
import IOrganization from '../../models/IOrganization';
import IOrganizationTeam from '../../models/IOrganizationTeam';
import OrganizationProvider from '../../providers/organizationProvider';
import IOrganizationService from '../../services/IOrganizationService';
import ITeam from '../../models/ITeam';

interface ISiteProps {
    type: string;
}

function Site(props: ISiteProps) {

    const organizationService: IOrganizationService = OrganizationProvider.getService();
    const siteService: ISiteService = SiteProvider.getService();
    const routes: IRoutes = siteService.getRoutes(window.location) as IRoutes;

    const [loading, setLoading] = useState<boolean>(true);
    const [organizationTeams, setOrganizationTeams] = useState<IOrganizationTeam[]>([]);
    const [organization, setOrganization] = useState<IOrganization>();
    console.clear();
    console.log('site loading');
    console.log(routes);

    const onLoad = () => {
        if (routes.location !== "") {
            // Get A Specification Location
            const path: string = routes.organization + "/" + routes.location;
            if (routes.team !== "") {
                organizationService.getTeamByPath(path, routes.team)
                    .then((response: IOrganizationTeam) => {
                        const orgs: IOrganizationTeam[] = [];
                        orgs.push(response);
                        setOrganizationTeams(orgs);
                        setOrganization(response);
                        setLoading(false);
                    });
            }
            else {
                organizationService.getTeamsByPath(path)
                    .then((response: IOrganizationTeam) => {
                        const orgs: IOrganizationTeam[] = [];
                        orgs.push(response);
                        setOrganizationTeams(orgs);
                        setOrganization(response);
                        setLoading(false);
                    });
            }
        }
        else {
            // Get All Locations
            organizationService.getTeamsByName(routes.organization)
                .then((response: IOrganizationTeam[]) => {
                    setOrganizationTeams(response);
                    if (response.length > 0) {
                        setOrganization(response[0]);
                    }
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    const renderOrganizationTeams = (org: IOrganization | undefined, orgs: IOrganizationTeam[]) => {

        //console.log('orgsLength: ' + orgs.length);
        console.log(orgs);
        if (orgs.length === 1) {
            if (orgs[0].teams.length === 1) {
                console.log('loop 1');
                return (
                    <Roster teamId={orgs[0].teams[0].id} teamName={orgs[0].teams[0].name} />
                )
            }
            else {
                console.log('loop 2');
                return (
                    orgs.map(o => {
                        return (
                            <div key={o.id}>
                                <div className="border-bottom mb-3">{o.location}</div>
                                {
                                    //    o.teams.map(t => {
                                    //        return (

                                    //        )
                                    //    })
                                }
                            </div>
                        )
                    })
                )
            }
        }
        else {
            console.log('loop 3');
            return (
                <div>
                    <div className="border-bottom mb-3">{org?.name}</div>
                    {
                        orgs.map(o => {
                            return (
                                <div key={o.id}>
                                    <div className="border-bottom mb-3">{o.location}</div>
                                    {
                                    //    o.teams.map(t => {
                                    //        return (
                                            
                                    //        )
                                    //    })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }

    //useEffect(() => {
    //    console.log('calling onLoad from type change');
    //    onLoad();
    //}, [type]);

    return (
        <div className="site-page">
            {
                loading ?
                    <div></div>
                    :
                    renderOrganizationTeams(organization, organizationTeams)
            }
        </div>
    );
}

export default Site;
